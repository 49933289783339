.Smartcloud-container {
  background-color: purple;
  font-size: 32px;
  width: 100%;
  height: 220px;
  padding-top: 100px;
  padding-left: 87px;
  color: white;
  font-family: 'Oswald', sans-serif;

  /* font-family: 'Didot'; */
  
}








/* card start */


/* Card Container */
.Smart-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-left: 87px;
  margin-right: 87px;
}

/* Card */
.Smart-card {


  background-color: #fcc7c7;
  margin-top: 45px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



/* Card Content */
.Smart-card-content {
  padding: 20px;

}

/* Card Title */
.Smart-card-title {
  font-size: 24px;
  font-family: 'Didot';
  font-weight: bold;
  margin: 0;
}

/* Card Description */
.Smart-card-description {
  font-family: 'Didot';
  margin-top: 10px;
  color: black;
}

/* Hover Effect */
.Smart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}


/* card end */




.smartp1 {
  width: 100%;
  height: 100%;
  margin-top: 5rem;
}



.SMV {
  font-weight: bold;
  margin-left: 87px;
  margin-right: 87px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 25px;
  font-family: 'Didot';
}

.SMV1 {
  background: #fcc7c7;

  padding: 2rem;
  cursor: pointer;
  transition: all 400ms ease-in;
  margin-left: 87px;
  margin-right: 87px;
}

.SMV1_container {
  display: grid;

  gap: 1rem;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  transition: all 400ms ease-in;
}

.SMV1:hover {
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.4);
  z-index: 1;

}

.SVM2 {
  height: 10rem;

}

.SVM2head {
  font-weight: 600;
  font-family: 'Didot';

}
.SVM2para{
  font-weight: 300;
  font-family: 'Didot';

}









/* smart-faqs */

.smart-faqs {

  height: 70rem;
  margin-left: 87px;
  margin-right: 87px;

}

.smart-faqs_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

}

.smart-faq {
  padding: 0.5rem;


  display: flex;
  align-items: center;
  gap: 1rem;
  height: fit-content;
  background: #fcc7c7;

  cursor: pointer;
}

.smart-question_answer {

  height: 15rem;
}
.smart-question_answer h4 {
  font-weight: 600;
  font-family: 'Didot';
}

.smart-question_answer p {
  
  font-family: 'Didot';
}

.smart-faq h4 {
  font-size: 1.2rem;
  line-height: 2.2;
}

.smart-faq p {
  margin: 0.8rem;
  font-size: 1rem;

}






.SM1 {
  background: #fcc7c7;
  cursor: pointer;
  transition: all 400ms ease-in;
  padding: 3rem;
  margin-bottom: 2rem;
  margin-left: 87px;
  margin-right: 87px;

}

.SM1_container {
  display: grid;

  gap: 1rem;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  transition: all 400ms ease-in;
}

.SM1:hover {
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.2);
  z-index: 1;

}

.SM2 {
  height: 9rem;

}
.SM2 p{
  font-family: 'Didot';
  

}



.SM2para {
  font-family: 'Didot';
  
  /* font-size: 25px; */

}






/* responsive website */





@media only screen and (max-width: 1060px) {

  .Smart-card-grid {

    grid-template-columns: repeat(2, 1fr);




  }






}


@media only screen and (max-width: 1028px) {

  .SVM2 {
    height: fit-content;
  }


  .smart-faqs_container {

    grid-template-columns: 1fr;
    gap: 40px;


  }

  .smart-faqs {
    height: fit-content;

  }

  .SM2 {
    height: fit-content;
  }

}


@media only screen and (max-width: 1024px) {

  

  .Smartcloud-container {
    padding-left:23px;
  }
  .Smart-card-grid {
    margin-left:23px;
    margin-right: 23px;
  }
  .SMV {
    margin-left: 23px;
    margin-right: 23px;
  }
  .SMV1 {
    margin-left: 23px;
    margin-right: 23px;
  }
  .smart-faqs{
    margin-left: 23px;
    margin-right: 23px;
  }
  .SM1{
    margin-left: 23px;
    margin-right: 23px;
  }

  
}



@media only screen and (max-width: 825px) {

  .Smart-card-grid {

    grid-template-columns: 1fr;

  }

  .Smart-card {
    margin-top: 10px;
  }
}



@media only screen and (max-width: 767px) {


  .Smartcloud-container {
    padding-left: 21px;
  }

  .Smart-card-grid {

    margin-right: 21px;
    margin-left: 21px;


  }

  .SMV {

    margin-right: 21px;
    margin-left: 21px;
  }

  .SMV1 {
    margin-right: 21px;
    margin-left: 21px;
  }

  .smart-faqs {
    margin-right: 21px;
    margin-left: 21px;
  }

  .SM1 {

    margin-right: 21px;
    margin-left: 21px;

  }
}



@media only screen and (max-width:385px) {
  .smart-question_answer {
    height: 18rem;
  }


}


@media only screen and (max-width:298px) {

  .smart-question_answer {
    height: 21rem;
  }


}


@media only screen and (max-width:277px) {

  .smart-question_answer {
    height: 23rem;
  }


}