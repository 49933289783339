.carousel-caption{
    height: 200px;
}

.circle-h11{
    margin-top: -90px;
    margin-bottom: 40px;
    color: black;
   
  }
  .circle {
    width: 300px;
    height: 300px;
    background-color: #727171;
    border-radius: 50%;
    text-align: center;
    position: relative;
    margin-left: 350px;
    margin-top: 30px;
  
    
  }
  
  .center-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 27px;
    font-weight: bold;
  }
  
  
  
  .section {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    padding: 20px;
    background-color: #fff;
  }
  
  .retail {
    position: absolute;
    color:black;
    top: -75px;
    left: -65%;
     margin-top: 70px;
    transform: translateX(-50%);
  }
  
  .loans {
    position: absolute;
    right: -330px;
    top: -1%;
    margin-top: 30px;
    transform: translateY(-50%);
    color:black;
  }
  
  .insurance {
    position: absolute;
    top: -60px;
    left: -65%;
     margin-top: 270px;
    transform: translateX(-50%);
    color:black;
  }
  
  .wealth {
    position: absolute;
    right: -330px;
    top: -1%;
    margin-top: 246px;
    transform: translateY(-50%);
    color:black;
  }