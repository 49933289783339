/* Default style for paragraphs */
p {
    font-weight: 500;
    margin: 0 0 10px;
    display: block;
    /* margin-block-start: 1em;
    margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: myriad-pro, Arial;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
  }
  
  /* Style for heading level 3 (.h3) */
  .h3 {
    font-weight: 900;
    margin: 10px 0;
  }
  .h2-heading{
    font-size: 32px;
    font-weight: 100;
    font-family: 'Tungsten A', 'Tungsten B', 'Khand', sans-serif;
    line-height: 70px;
    padding-bottom: 50px;
    color: white;
    padding-top: 100px;
}
  
  /* Mobile View */
  @media only screen and (max-width: 768px) {
    .pt75 {
      font-size: 16px;
      line-height: 1.5;
      padding-left: 20px;
    }
  
    .h3 {
      font-size: 24px;
    }
  }
  
 

 