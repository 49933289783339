@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

#maindivs {
  overflow-x: hidden;
  font-family: system-ui;
}
/* *{
  overflow-x: hidden;
} */

/* First slider */

/* ............ */
.component-1 {
  overflow: hidden;
  margin: 20px;
  font-size: 60px;
  /* line-height: 40px; */
  font-weight: 500;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  color: white;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
}
.component-paras {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 700px;
  justify-content: center;
}
.carousel-caption {
  overflow: hidden;
}

.container-np2 {
  overflow: hidden;
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #ececec url("/public/Images/images.jpg");
  flex-wrap: wrap;
  height: auto;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.cont2-titles {
  /* background-color: rgb(28, 122, 32); */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 80px;
  font-size: 50px;

  font-weight: bold;
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  /* flex-direction: column; */
}
.cont2-title {
  /* background-color: rgb(28, 122, 32); */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  /* margin-top: -400px; */
  font-size: 50px;
  /* line-height: 50px; */
  font-weight: bold;
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  /* flex-direction: column; */
  margin: 50px;
  display: flex;
  width: 100%;
}
.container21 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  width: 80%;
  background-color: transparent;
  /* height: 400px; */
  margin-left: 50px;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-top: -3.5%;
}
.cont2-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left-text,
.right-text {
  width: 100%;
  height: 96%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: transparent;
  font-size: 25px;
  line-height: auto;
  justify-content: center;
}
.cont2-right {
  background-image: url();
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.content2img {
  mix-blend-mode: multiply;
  width: 400px;
  height: 400px;
}
.readmore {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-color: black;
  /* margin-top: 30px; */
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}
/* .readmore2{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-color: black;
 
  flex-wrap: wrap;
} */
.read1 {
  width: 180px;
  height: 50px;

  font-size: 15px;
  background: linear-gradient(to right, rgb(0, 0, 0) 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.4s ease-in-out;

  border: 2px solid rgb(20, 20, 20);

  font-family: system-ui;
}
.read1:hover {
  background-position: left bottom;
  color: rgb(255, 255, 255);
  border: #fcfafa;
}

/* linking page */

.button-readmore {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.button-readmore:hover {
  background-color: #0056b3;
}

.content2img {
  /* margin-top: -30px; */
}
/* second container media query */
@media screen and (max-width: 767px) {
  .container-np2 {
    display: flex;
    height: auto;
    overflow-x: hidden;
  }
  .container21 {
    /* grid-template-rows: 1fr ; */
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border-radius: 2px solid black; */
    /* gap: 50px; */
    /* margin-top: 50px; */
    /* margin-left: -20px; */
  }
  .maindivs {
    overflow-x: hidden;
    width: 100vw;
  }
  .left-text {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    text-align: justify;
  }
  .right-text {
    width: 50%;
    height: 100%;
    display: flex;
  }
  .content2img {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: 10%;

    /* margin-left: -50px; */
  }
  .readmore {
    margin-top: 100px;
    /* margin-left: -7%; */
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  .container-np2 {
    display: flex;
    height: auto;
    overflow-x: hidden;
  }
  .container21 {
    /* grid-template-rows: 1fr ; */
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border-radius: 2px solid black; */
    /* gap: 50px; */
    /* margin-top: 50px; */
    margin-left: -20px;
  }
  .maindivs {
    overflow-x: hidden;
    width: 100vw;
  }
  .left-text {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    text-align: justify;
  }
  .right-text {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .content2img {
    width: 100%;
    height: 100%;
    justify-content: center;

    margin: auto;
    /* margin-left: -50px; */
  }
  .readmore {
    margin: 50px;

    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .component-paras {
    display: none;
  }

  .cont2-title {
    font-size: 25px;
    margin-left: 30px;
  }
}

/* section3 */
.section3 {
  overflow: hidden;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(253, 253, 253);
  align-items: center;
  justify-content: center;
  /* border-radius: 10%; */

  /* margin-top: 20%; */

  /* overflow: hidden; */
}

.title3,
.title3-heading {
  width: 90%;
  /* height: 20%; */
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.title3 {
  /* height: 10%; */
  margin: 50px;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.section3parts {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 750px;
  display: grid;
  /* padding: 20px; */
  /* margin-bottom: 30px; */
  /* overflow: hidden; */
  margin-bottom: 30px;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.section3parts-manageservice {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 450px;
  display: grid;
  /* padding: 20px; */
  /* margin-bottom: 30px; */
  /* overflow: hidden; */
  margin-bottom: 30px;
  overflow: hidden;
  grid-template-columns: repeat(4, 1fr);
}

.adm-readmore {
  text-decoration: none;
  color: orangered;
}
/* .adm-readmore:hover{
  
  color: white;
} */

.content-np {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-left: 15px;
  font-family: system-ui;
  font-size: 18px;
  letter-spacing: 1.5px;
  color: #f8f8f8;
  font-weight: 600;

  font-style: bold;
  font-weight: 600;
}

.item31 {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  overflow: hidden;
  background-image: url("/public/Images/coms2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* display: flex; */

  background-color: #357296;
}
/* .item322{
  background-image: url("/public/Images/img2");
} */
.item32-conent {
  top: 0;
  width: 100%;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.item32 {
  height: 100%;
  width: 100%;
  background-color: brown;
  overflow-y: hidden;
  /* background-image: url("item32\ \(2\).jpg"); */
}
.item31-conent-img {
  height: 40%;
  width: 95%;
  background-image: url("/public/Images/img2");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.item32-headings {
  color: white;
  font-size: 15px;
}
.item32-heading {
  color: #000;
  font-size: 18px;
}
.item33 {
  /* background-image: url("item32\ \(3\).jpg"); */
  /* background-image: url("/public/Images/img3.jpg");
background-position: center;
 background-repeat: no-repeat;
 background-size: cover; */
  background-color: #963596;
  /* color: white; */
}
.item34 {
  /* background-image: url("image5.jpg"); */
  background-color: #dfdfdf;
  height: 90%;
  margin-top: -13.5%;
  color: #000;
}
.item35 {
  background-color: rgb(235, 184, 73);
  height: 90%;
  margin-top: -13.5%;
  /* background-image: url("/public/Images/careers-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-image: url("image1.jpg"); */
}
.item36 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(250, 114, 90);
  height: 90%;
  margin-top: -13.5%;
  /* background-image: url("image2.jpg"); */
}
.item37 {
  /* background-image: url("image4.jpg"); */
  background-color: #df9926;
  height: 90%;
  margin-top: -13.5%;
  padding-left: 15px;
  background-image: url("/public//Images/coms1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
}
/* Add hover effect to each section */
.content-np:hover {
  transform: scale(1.05); /* Increase size on hover */
  transition: transform 0.3s ease; /* Add smooth transition */
}

/* Optionally, you can change other properties on hover */
.content-np:hover {
  /* Add any additional styling you want on hover */
}
.item3texts {
  color: #000;
}
.item3text {
  color: #000;
  margin-bottom: 30px;
}
/* If you want to change text color on hover */
.content-np:hover .item3text {
  color: white /* Your hover color */;
}

/* If you want to change background color on hover */

@media only screen and (max-width: 1122px) {
  .section3 {
    height: auto;
    width: 100%;
  }

  /* section 5 cards */

  /* section 5 media-query */
  @media only screen and (min-width: 350px) and(max-width: 821px) {
    .section5 {
      height: auto;
      width: 100vw;
    }
    .section3parts-manageservice {
      grid-template-columns: repeat(2, 1fr);
      height: auto;
    }
  }
  @media only screen and (min-width: 722px) and (max-width: 2000px) {
    .section5 {
      height: auto;
      width: 100vw;
    }
    .section3 {
      height: auto;
      width: 100%;
    }
  }
  /* section4 */
  /* Carousel.css */

  .section4,
  .sliders {
    height: 100vh;
    width: 100vw;

    overflow-x: hidden;
  }
  .cont2-title {
    /* border:2px solid #df9926; */
    height: 20%;
    margin: 20px;
  }
  .section3parts-manageservice {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;

    /* margin-top: 100px; */
    margin-top: 10px;
    width: 100%;
    /* margin-left: 20px; */
    text-align: justify;
    justify-content: center;
    align-items: center;
  }
  .section3parts {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;

    /* margin-top: 100px; */
    margin-top: 10px;
    width: 100%;
    /* margin-left: 20px; */
    text-align: justify;
    justify-content: center;
    align-items: center;
  }
  .item31 {
  }
  .content-np {
    height: 600px;
    font-size: 30px;
    margin-top: 5px;
    justify-content: center;
    padding: 10px;
  }
  .item3text {
    font-size: 20px;
    margin-top: 2px;
  }
  .item32-heading {
    color: #000;
    /* font-size: 30px; */
  }
  .item32-headings {
    color: white;
    font-size: 30px;
  }
  .aligned-paragraph {
    text-align: justify;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 20px;
    font-size: 16px;
    color: white;
    font-family: system-ui;
  }
}

@media only screen and (min-width: 722px) and (max-width: 1025px) {
  .section3parts {
    display: grid;
    width: 85%;
    height: auto;
    grid-template-columns: 1fr 1fr;
  }
  .section3parts-manageservice {
    display: grid;
    width: 85%;
    height: auto;
    grid-template-columns: 1fr 1fr;
  }
  .section3 {
    height: 100%;
  }
  .item32-heading {
    color: #fff;
  }
  .item32-headings {
    color: white;
    font-size: 30px;
  }
  .aligned-paragraph {
    text-align: justify;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 20px;
    font-size: 25px;
    color: white;
    font-family: system-ui;
  }
}

.container2s {
}

.aligned-paragraph {
  text-align: left;
  margin-top: 10px;
  margin-left: 18px;
  height: 55%;
  margin-right: 20px;
  font-size: 16px;
  color: white;
  font-family: system-ui;
}

.readmores {
  border-color: black;
  margin-bottom: -100px;
}

.readmores button {
  width: 180px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: center;
  /* display: block; */
  font-size: 15px;
  background: linear-gradient(to right, rgb(29, 12, 94) 50%, white 50%);
  /* background-size: 200% 100%; */
  background-position: right bottom;
  transition: all 0.5s ease-out;
  /* margin: 60px; */
  border: 2px solid rgb(20, 20, 20);
  /* margin-top: -100px; */
  font-family: system-ui;
}
.readmores button:hover {
  background-position: left bottom;
  color: rgb(255, 255, 255);
  border: #fcfafa;
}

/* Section 5 */
.section5 {
  /* margin-top: 10px; */
  /* height: 100vh; */

  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  align-items: center;
  justify-content: center;
  background-color: #211947;
  color: rgb(255, 255, 255);
}
.section5heading {
  width: 80%;
  height: auto;
  justify-content: center;
  font-size: 60px;
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  overflow: hidden;
  margin: 30px;
  text-align: center;
}
.section5heading h1,
.section5heading h3 {
  width: 100%;
}
/* section 5 cards */

/* section 5 media-query */
@media only screen and (max-width: 821px) {
  .section5 {
    height: auto;
    width: 100vw;
  }
}
@media only screen and (min-width: 822px) and (max-width: 998px) {
  .section5 {
    height: auto;
    width: 100vw;
  }

  .item5content {
    width: 100%;
    display: grid;
    height: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .item5text .a-np {
    font-size: 20px;
    font-family: system-ui;
  }
  .aligned-paragraph {
    text-align: justify;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 20px;
    font-size: 25px;
    color: white;
    font-family: system-ui;
  }
}
/* section4 */
/* Carousel.css */

.section4,
.sliders {
  /* margin-top: 3%; */
  height: auto;
  width: 100vw;
  overflow: hidden;
}
.slick-slider {
  position: relative;
  width: 84%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 9%;
  /* margin-right: 10%; */
  margin-top: 5%;
  overflow: hidden;
}

/* Slide item */
.slider-item {
  width: 450px;
  overflow-y: hidden;
  position: relative;
  height: 600px; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #875353; /* Text color */
  /* Adjust padding as needed */
  /* box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);  */
  /* Optional: Add box shadow for a card-like effect */
}

.modal-np {
  font-size: 15px;
  font-family: system-ui;
  padding: 0 20px;
}
.slider-item .h2-np {
  font-size: 30px;
  margin-bottom: 15px;
  margin-top: 20%;
  padding-top: 5px;
  width: 100%;

  justify-content: center;
  text-align: center;
  height: 150px !important;
}

.slider-item .p-np {
  font-size: 16px;
  margin-bottom: 10px;
  height: 40%;
  /* margin-top: 40px; */
  justify-content: center;
  text-align: center;
}

/* Arrow buttons */
.nav-button-slider {
  /* position: absolute; */

  background-color: transparent;
  color: #4e4b4b;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  position: absolute;
  height: 600px;
  width: 70px;
  z-index: 111;
  overflow: hidden;
  /* background-color: #963596; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
  font-size: 50px;
}

.prev {
  left: 2%;
  /* top: 50%; */
}

.next {
  right: 2%;
}

.slidecontent {
  color: black;
  width: 100%;
  /* margin-top: 30%; */
  justify-content: center;
  overflow: hidden;
  height: 60%;
  /* background-color: #963596; */
}
.slidecontents {
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* border: 2px solid black; */
  /* border-radius: 20px; */
  padding: 20px;
  width: 90%;
  height: 80%;
  background: #bee3ff url("/public/Images/images.jpg");
  background-size: cover; /* Adjust background size to your preference */
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url("/public/Images/slide1.jpg"); */
}
.slidecontents:hover {
  transform: scale(1.1, 1.1);
  transition: all ease-in 0.2s;
  box-shadow: 7px 7px 5px rgb(88, 4, 88);
  border-radius: 20px;
}
.slideheading {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
/* carosole modal */

.modal-overlay {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content-m {
  background: white;
  position: fixed;
  padding: 20px;

  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 95%;
  height: 90%;
  overflow-y: auto;
}

/* The Close Button */
#close-m,
#close-np {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: right;

  width: 100%;
  height: auto;
}

#close-m:hover,
#close-m:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Modals */

/* Modal Content */

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */

  background: rgba(0, 0, 0, 0.5);
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  background-image: url("/public/Images/bg-model-wv.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  padding: 20px;
  margin-left: 2.5%;
  margin-top: 2.5%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 95%;
  height: 90%;
  overflow-y: auto;
}

/* The Close Button */
#close,
.close-np {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: right;

  width: 100%;
  height: auto;
}

#close:hover,
#close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.section6 {
  height: auto;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: system-ui;
  overflow: hidden;
  margin-bottom: 5%;

  /* font-size: 18px;
  font-weight: 300; */
}
.section6title {
  margin-left: 10%;
  display: flex;

  flex-wrap: wrap;
}
._6textheading {
  font-size: 40px;
  color: #000;
  font-weight: 900;
  line-height: 40px;
}

.section6title-np,
.section6title-np2 {
  display: flex;
  width: 100%;
}

.section6title-np2 {
  font-size: 30px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 40px;
  color: #7e7e7e !important;
  box-sizing: border-box;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-left: 2%;
}
.section6title-np1 {
  font-size: 70px;
  font-weight: 500;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  line-height: 70px;
}
.section6row {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  /* background-color: brown; */
  height: auto;
  width: 100%;
  margin-left: 15%;

  display: flex;
  flex-wrap: wrap;
  border-left: none;
}
/* colom section in rows */
.section666 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;

  height: auto;
  justify-content: center;
  border-left: none;

  border-top: 1px solid black;
}
.section666col {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  justify-content: center;
  /* align-items: center; */
}
._6text {
}
.a-np {
  font-family: system-ui;
  text-decoration: none;
  color: black;
  font-size: 20px;
  color: #000;
  font-weight: 400;
  line-height: 25px;
  text-align: -webkit-match-parent;
}
li {
  list-style-type: none;
  font-size: 18px;

  /* line-height: 26px; */

  display: list-item;
  /* text-align: -webkit-match-parent; */
}

/* Media query for devices with a maximum width of 768 pixels */
@media only screen and (max-width: 768px) {
  .section666 {
    grid-template-columns: 1fr;
  }
  .section6row {
    margin-left: 5px;
    width: 100%;
  }
  .section6title-np {
    margin-left: 5px;
  }
  .carousel-caption {
    height: auto !important;
  }
  .section3parts-manageservice {
    height: auto;
  }
  .section3parts {
    height: auto;
  }

  .slick-slider {
    margin-left: 10.2%;
  }
  /* .prev{
    margin-left: 0;
  } */
}
.component-1 {
  font-size: 50px;
}

@media only screen and (max-width: 685px) {
  .prev {
    left: 1%;
  }
  .next {
    right: 1%;
  }
  /* .slick-slider {
    margin-left: auto;
    margin-right: auto;
  } */
}

@media only screen and (max-width: 500px) {
  /* .nav-button-slider{
    width: auto;
  }
  .next{
    right: 3%;
  }
  .prev{
    left:0.1%;
  } */

  .slidecontents {
    width: 95%;
  }
}

@media only screen and (max-width: 866px) {
  .carousel-caption {
    height: auto !important;
  }
}

@media only screen and (max-width: 406px) {
  .component-1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 306px) {
  .component-1 {
    font-size: 30px;
  }
}

.main-cont {
  background-color: #342e39;
  font-family: system-ui;
  padding-top: 2rem;
}

.cardcomp {
  padding-left: 6rem;
  padding-right: 6rem;
}

.cardcomp .row-ca {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.cardcomp .row .col-ca {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.cardhead {
  text-align: center !important;
  margin-bottom: 6.5rem;
}
.head-t {
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  font-size: 3rem;
  line-height: 6rem;
  margin-bottom: 1rem;
  color: white;
}

.t-color {
  color: #eee;
  margin-bottom: 0rem;
}

.card-wrapper {
  position: relative;
  font-family: system-ui;
  width: 23rem; /* Set the initial width of the cards */
  height: 10rem; /* Set the initial height of the cards */
  /* transition: all cubic-bezier(0, .95, 1, .66); */
}

.main-card,
.hover-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  overflow: hidden;
}

.main-card {
  background-color: #fff; /* Set the background color of the main card */
  z-index: 2;
  padding: 10px;
  font-size: 1rem;
}

.hover-card {
  font-family: system-ui;
  background-color: #a7a5a9;
  z-index: 1;
  opacity: 0;
  /* Set the height of the hover card */
  transition: all 0.3s ease-in-out;
}

.card-wrapper:hover .main-card {
  opacity: 0;
}

.card-wrapper:hover .hover-card {
  z-index: 4;
  opacity: 1;
  transform: scale(1.2, 1.5);
  /* transform: scaleY(2); */
}

.main-card img {
  margin-top: 10px;
  margin-bottom: 10;
  width: 5rem;
  height: 5rem;
  padding-top: 0.8rem;
}
@media only screen and (min-width: 1140px) {
  .main-card .img-4 {
    margin-top: 10px;
    margin-bottom: 10;
    width: 7rem;
    height: 7rem;
    padding-top: 0.8rem;
  }
}

.card-content1,
.hover-content {
  padding: 0.2rem;
}

.hover-content h2 {
  color: #fff;
  display: inline-block;
  position: relative;
  font-size: 1rem;
  padding: 10px;
  border-bottom: 1.5px solid #fd5f07;
}

.hover-content p {
  font-size: 0.8rem;
  color: #fff;
  padding: 5px 20px;
}

.card-container {
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.row-ck {
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.col-ck {
  flex: 0 0 0%; /* Adjust the width of each column */
  width: 100%;
  /* Adjust the maximum width of each column */
  margin: 1rem;
}

.innercon {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.card-content1 {
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline;
}

.card-content1 h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-content1 p {
  font-size: 1rem;
  color: #666;
}
.col-ck {
  width: 100%;
}

.card-wrapper-4 {
  width: 29rem;
}

@media only screen and (max-width: 1360px) {
  .card-wrapper {
    width: 21rem;
  }
}

@media only screen and (min-width: 1140px) and (max-width: 1360px) {
  .card-wrapper-4 {
    width: 25rem;
  }
}
@media only screen and (max-width: 1098px) {
  .card-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media only screen and (max-width: 746px) {
  .col-ck {
    margin: 0.6rem;
  }

  .card-wrapper {
    width: 16rem;
    height: 7rem;
  }
}

@media only screen and (max-width: 686px) {
  .col-ck {
    margin: 0.5rem;
  }

  .card-content1 h2 {
    font-size: 1rem;
  }

  .card-wrapper {
    width: 14rem;
    height: 6rem;
  }

  .hover-content h2 {
    font-size: 0.8rem;
  }

  .hover-content p {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 545px) {
  .col-ck {
    margin: 0.5rem;
  }

  .card-wrapper {
    width: 23rem;
    height: 10rem;
  }
}

@media only screen and (max-width: 430px) {
  .col-ck {
    margin: 0.5rem;
  }

  .card-wrapper {
    width: 17rem;
    height: 8rem;
  }
}

@media only screen and (max-width: 342px) {
  .col-ck {
    margin: 0.4rem;
  }

  .card-wrapper {
    width: 13rem;
    height: 6rem;
  }
}

@media screen and (max-width: 350px) {
  .section3parts-manageservice {
    grid-template-columns: 1fr;
    height: auto;
  }
}
/* section7 */
.section7 {
  margin-top: 10px;
  height: auto;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  /* background-image: url("/public/Images/backgroundofservice.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  background-color: rgba(0, 0, 0, 0.568);
  /* opacity: ; */
}
.section7::after {
}

.section7headding {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  align-items: center;
  /* margin-top: -2%; */
  height: 200px;

  justify-content: center;
}

.cards-np7 {
  width: 100%;
  height: 100%;

  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  /* max-width: 820px; */
}

.card__img-np71,
.card__img--hover-np71 {
  background-image: url("/public/Images/pexels-pixabay-210600.jpg");
}

.card__img-np72,
.card__img--hover-np72 {
  background-image: url("/public/Images/pexels-total-shape-2383010.jpg");
}
.card__img-np73,
.card__img--hover-np73 {
  background-image: url("/public/Images/pexels-karolina-grabowska-5632382.jpg");
}
.card__img-np74,
.card__img--hover-np74 {
  background-image: url("/public/Images/manfacturingimg1.jpg");
}
.card__img-np75,
.card__img--hover-np75 {
  background-image: url("/public/Images/pexels-tima-miroshnichenko-5380664.jpg");
}
.card__img-np76,
.card__img--hover-np76 {
  background-image: url("/public/Images/pexels-brett-sayles-937524.jpg");
}

/* .card__img-np7,
.card__img--hover-np7 {
  background-image: url("/public/Images/backgroundofservice.jpg");
} */

.card__like-np7 {
  width: 18px;
}

.card__clock-np7 {
  width: 15px;
  vertical-align: middle;
  fill: #ad7d52;
}
.card__time-np7 {
  font-size: 12px;
  color: #ad7d52;
  vertical-align: middle;
  margin-left: 5px;
}

.card__clock-info-np7 {
  float: right;
}

.card__img-np7 {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card__info-hover-np7 {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover-np7 {
  transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 105px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;
}
.card-np7 {
  margin-right: 25px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  height: 200px;
  width: 300px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}
.card-np7:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.1, 1.1);
}

.card__info-np7 {
  z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 24px 24px 24px;
}

.card__category-np7 {
  font-family: "system-ui";
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #868686;
}

.card__title-np7 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: system-ui;
}

.card__by-np7 {
  font-size: 12px;
  font-family: system-ui;
  font-weight: 500;
}

.card__author-np7 {
  font-weight: 600;
  text-decoration: none;
  color: #ad7d52;
}

.card-np7:hover .card__img--hover-np7 {
  height: 100%;
  opacity: 0.3;
}

.card-np7:hover .card__info-np7 {
  background-color: transparent;
  position: relative;
}

.card-np7:hover .card__info-hover-np7 {
  opacity: 1;
}
.section7slider {
  /* margin-top: 15px; */
  /* margin-top: -10%; */
  height: 300px;
  width: 90%;

  /* background-color: #007bff; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #007bff; */
}
.cards7np {
  height: 100%;

  width: 100%;
  justify-content: center;
  /* margin-top: -1%; */
}

.sec7 {
  font-size: 25px;
  font-weight: 500;
  margin-left: 7%;
}

.headt-7p {
  width: 100%;
  margin: 0% 10%;
  font-size: 20px;
  justify-content: center;
  display: flex;
  font-family: system-ui;
}
.section7bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: -150px; */
  height: 250px;
  margin-bottom: 20px;

  justify-content: center;
  /* margin-top: -10%; */
  /* height: 10%; */
  /* justify-content: center; */
  animation-name: slideIn;
  animation-duration: 1s;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.bottom7text {
  display: grid;
  /* margin-top: -20%; */
  width: 85%;
  height: 100%;
  /* border: 2px solid white; */
  border-radius: 30px;
  background-color: #0b0e109d;
  /* margin-left: 150px; */
}
.bottom7text h2 {
  padding: 10px 50px;
  font-size: 40px;
  width: 100%;
  height: 10%;
  color: rgb(243, 242, 241);
}
.bottom7text p {
  padding: 10px 50px;
  font-size: 25px;
  height: 60%;
  width: 100%;
  color: rgb(206, 204, 199);
}
.bottom7texth {
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  font-size: 3rem;
  line-height: 4rem;
  margin-bottom: 1rem;
  color: white;
}
@media only screen and (max-width: 548px) {
  .section7bottom {
    height: 400px;
  }
  .bottom7text h2 {
    font-size: 26px;
  }
  .bottom7text p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .section7slider {
    margin-top: 10%;
  }
  .section7bottom {
    height: 300px;
    /* margin-top: -10%; */
  }
  .bottom7text h2 {
    font-size: 26px;
  }
  .bottom7text p {
    font-size: 16px;
  }
  .card-np7 {
    margin-right: 100px;
  }
}
@media only screen and (max-width: 400px) {
  .section7slider {
    margin-top: 150px;
  }
  .section7bottom {
    height: 400px;
  }
}

.modal-sub-sk {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  user-select: none;
}

.card-con-sk {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
}

.card-con-sk-4 {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 450px;
  height: 300px;
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
}

.card-con-sk::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: orange;
  height: 220px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1, 10);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card-con-sk-4::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: orange;
  height: 220px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1, 10);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card-con-sk:hover::before,
.card-con-sk-4:hover::before {
  transition-delay: 0.2s;

  transform: scale(200);
}

.card-con-sk:hover,
.card-con-sk p:hover,
.card-con-sk-4:hover,
.card-con-sk-4 p:hover {
  color: #ffffff;
}

.card-con-sk p,
.card-con-sk-4 p {
  padding: 10px 0;
}

.row-sk {
  margin: 15px;
}

@media only screen and (max-width: 600px) {
  .card-con-sk,
  .card-con-sk {
    height: auto;
  }

  .modal-np {
    padding: 0;
  }
}

@media only screen and (max-width: 1054px) {
  .card-con-sk-4 {
    width: 400px;
  }
  .row-sk {
    margin: 6px;
  }
}

@media only screen and (max-width: 912px) {
  .card-con-sk-4 {
    width: 500px;
    height: auto;
  }
  .row-sk {
    margin: 6px;
  }
}

@media only screen and (max-width: 580px) {
  .card-con-sk-4 {
    width: 99%;
    height: auto;
  }
}

.a-box {
  display: inline-block;
  text-align: center;
}

.img-container {
  height: 300px;
  width: 280px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  transform: skew(0deg, -13deg);
  height: 300px;
  margin: -35px 0px 0px -70px;
  mix-blend-mode: multiply;
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  transform: skew(0deg, 13deg);
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 300px;
  width: 280px;
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 18px;
  font-family: system-ui;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #000;
  font-size: 24px;
  font-weight: 700;
}

.paraText-h6 {
  line-height: 20px;
  text-align: left;
  font-size: 16px;
  font-family: system-ui !important;
  line-height: 22px;
  font-weight: 500 !important;
  letter-spacing: 1.2px;
}

.card-container-sk {
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 730px) {
  .a-box {
    display: inline-block;
    width: 700px;
    text-align: center;
  }
}

/* cookies css */

.cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookies-banner p {
  margin: 0;
  color: white;
  font-size: 14px;
}

.cookies-banner button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.cookies-banner button:hover {
  background-color: #45a049;
}

@media only screen and (max-width: 768px) {
  .cookies-banner {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .cookies-banner button {
    margin-top: 10px;
  }
}
.newsection5 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.newsection5grid {
  display: grid;
  grid-template-columns: 20% 80%;
  margin-top: 20px;
}
.section5punchline {
  width: 85%;
  height: 10%;
  font-family: system-ui;
  font-weight: 300;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.section5newgrid {
  width: 80%;
  display: grid;
  height: 70%;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-bottom: 30px;
}
.newsection5-np {
  width: 100vw;
  height: 110vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.newsection5headding {
  font-size: 25px;
  font-family: system-ui;
  font-weight: 400;
}
.newsection5grid:hover {
  color: #007bff;
  /* background-color: orange; */
  transform: translateY(-5px);

  transition: all ease-in-out 0.5s;
}
.newsection5text {
  font-size: 18px;
  font-family: system-ui;
  font-weight: 200;
  color: #000000b8;
}
.newsection5img {
  height: 55%;
  width: 95%;
}
@media only screen and (max-width: 900px) {
  .section5newgrid {
    grid-template-columns: 1fr;
  }
  .newsection5-np {
    height: auto;
  }
  .newsection5grid {
    grid-template-columns: 1fr;
    gap: none;
  }
  .newsection5col1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .newsection5img {
    height: 70%;
    width: 40%;
    display: flex;
  }
}
.newsection5img {
  mix-blend-mode: darken;
}
.newsection5-np {
  background-image: url("/public/Images/images.jpg");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  background-color: #02020223;
}
.FAQ {
  display: grid;
  width: 100vw;
  height: auto;
}
.FAQbody {
  display: grid;
  width: 70%;
  margin: 10px auto;
}
