.title-sml {

  font-size: 42px;
  color: #000000;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: -2px;
  margin-top: -40px;
}

#overview {
  padding: 75px 0;
  position: relative;
}

#main-text-body {
  padding: 13px;
}

.pb0-overview {
  padding-bottom: 0 !important;
  margin-top: 20px;
}

.h2-heading {
  font-size: 50px;
  font-weight: 500;
  line-height: 70px;
  color: black;
}

.M {

  color: black;
  margin-top: 20px;
  font-size: 42px;
  font-weight: 500;
  line-height: 70px;
  color: black;
  margin-top: -20px;

}

.p-3 {
  padding: 1rem !important;
  color: white;
}

.ab {

  margin-top: -48px;
  margin-bottom: 22px;
}

.carousel-inner {
  margin: 0 !important;
  padding: 0 !important;
}

#lrow {
  margin: 0 !important;
}



/* Media query for mobile view */
@media (max-width: 768px) {

  .about-containerss {
    margin-left: -3%;
  }

  .title-sml {
    font-size: 24px;
    /* Decrease the font size of the headings */
    margin-bottom: 10px;
    /* Add some space below the headings */
  }

  .container p {
    font-size: 16px;
    /* Decrease the font size of paragraphs */
    margin-bottom: 20px;
    /* Add more space between paragraphs */
  }

  .col-md-10 {
    width: 100%;
    /* Make the text content width 100% for small screens */
  }
}

/* Add this CSS to your stylesheet */
#row {
  margin-top: 20px;
}

.row.col-md-7 {
  max-width: 350px;
  /* Limit the width of the container */
  margin: 0 auto;
  /* Center the container */
}

.ccol {
  /* font-size: 14px; Adjust the font size */
  padding: 10px;
  /* Adjust the padding */
  border: 0.5px solid #ccc;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
}

.col:hover {
  background-color: #f5f5f5;
}

.col a {
  text-decoration: none;
  color: #333;
}

.about-header {
  background-color: #f8f9fa;
  padding: 100px 0;
}



.about-team {
  padding: 100px 0;
}

.aboutheadings {
  margin-left: 12%;
  font-family: o;
  font-size: 40px;
  font-family: 'Oswald', sans-serif;
}

.text-muted {
  margin-left: 12%;
  font-family: system-ui;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .about {
    padding-top: 40px;
    text-align: left;
  }

  .img-fluid {
    margin-top: -20px;
  }

  .img-fluids {
    margin-top: 70px;
  }

  .text-muted {
    text-align: left;
  }
}

@media (max-width: 576px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }



}

.carousel-item {
  background-image: url("/public/Images/abtbannar.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 130% !important;
}

.abt-heading {
  height: 100%;
  width: 100%;

}

.overlay-sk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.abt-head-sk {
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  position: relative;
  z-index: 1;
  color: #fff;
  height: 80vh !important;
  width: 100%;
  font-size: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:425px) {
  .abt-head-sk {
    font-size: 40px;
  }
}