/* Cookie.css */
 
/* Add your existing CSS styles here */
 
/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
  #termsedit1s {
    font-size: 30px; /* Decrease font size for smaller screens */
    margin-left: 2%; /* Adjust margin for smaller screens */
    margin-top: 15px; /* Adjust margin for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }
 
  .termsheading {
    padding-top: 30px;
    font-size: 1.8rem; /* Decrease font size for smaller screens */
    line-height: 2.5rem; /* Decrease line height for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }
 
  .termsheadings {
    font-size: 1.3rem; /* Decrease font size for smaller screens */
    margin-top: 15px; /* Adjust margin for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }
 
  .termsubpoint {
    font-size: 1rem; /* Decrease font size for smaller screens */
    margin-top: 5px; /* Adjust margin for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }
 
  #terms-list {
 
    font-size: 1rem; /* Decrease font size for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }
 
  #terms-list li::before {
    font-size: 1.2rem; /* Decrease bullet size for smaller screens */
  }
}