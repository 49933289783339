.bussiness-modal{
    height: 100vh;
    z-index: 1000;
    background-color: #fff;
    color: black;
    width: calc(100% - 300px);
    transition: ease-in 1s all;
    overflow: auto;
}

