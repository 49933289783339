.customerservicing-heading{
    background-color: purple;
     font-size: 32px;
     width: 100%;
     height: 220px;
     padding-left: 7%;
     padding-top: 100px;
     color: rgb(254, 253, 253);
     font-weight: bold;
     font-family: 'Oswald', sans-serif;

     @media (max-width: 768px){
        padding-left: 5px;
     }
     
     
 }
 .customerservicingimges{
   
   margin-right: 20px;
   margin-top: -304px;
  float: right;
     max-width: 400px; /* Adjust the image width as needed */
     max-height: 300px; /* Adjust the image height as needed */


 }

 @media (max-width: 768px) {
    .customerservicingimges {
      display: none;
    }
  }