
#headinga{
    color: white;
    font-size: 33px;
    width: 100%;
    height: 120px;
   margin-left: 12.2%;
    margin-top: 80px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}





#sml_ht_home_banner{
    background-color: purple;
}
#pt75s{
    /* padding-top: 50px !important;
    max-width: 70%;
    margin-left: 12.2%;
    margin-bottom:30px;
    margin-right: 30px; */
   
    
}

#pt75{
    
   
  
   
   
   
}

#pts75{
    padding-top: 50px !important;
    max-width: 72%;
   
    margin-bottom:30px;
   
   
}
p{
    margin: 0 0 10px;
    font-family: myriad-pro, Arial;
    font-size: 26px;
    font-weight: 300;
    color: #000000;
   
}
h2 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h3{
    font-weight: 900;
    margin: 10px 0;
}
ul {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: myriad-pro, Arial;
    font-size: 26px;
    font-weight: 300;
    color: #000000;
}
#page-innertitle{
    padding-left: 31%;
}
#img{
    padding: 10px;
}
@media only screen and (max-width: 768px) {
    #pt75 {
      /* font-size: 16px;
      line-height: 1.5;
      padding-left: 20px !important; */
      grid-template-columns:1fr ;
      grid-template-columns: 1fr ;
    }
  
    .h3 {
      font-size: 24px;
    }
    #page-innertitle {
        padding-left: 2%;
    }
    ul{
        padding-left: 0 !important;
    }
    #col2{
        margin-top: 0 !important;
    }
    #pt75s{
        margin-left: 20px;
    }
  }



 /* new changes in online tutor platform */

 /*  online tutor platform  start*/

 .OnlineTP-container {
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-top: 100px;
    padding-left: 87px;
    color: white;
    font-family: 'Oswald', sans-serif;
  
    /* font-family: 'Didot'; */
    
  }

  .OTP{
    font-weight: bold;
    margin-left: 87px;
    margin-right: 87px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size:25px;
    font-family: 'Didot';
  }

  /* card start */


/* Card Container */
.OnlineTP-card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-left: 87px;
    margin-right: 87px;
    margin-bottom: 2rem;
  }
  
  /* Card */
  .OnlineTP-card {
  
  
    background-color: #fcc7c7;;
    margin-top: 45px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  
  /* Card Content */
  .OnlineTP-card-content {
    padding: 20px;
  
  }
  
  
  
  /* Card Description */
  .OnlineTP-card-description {
    font-family: 'Didot';
    margin-top: 10px;
    color: black;
  }
  
  /* Hover Effect */
  .OnlineTP-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  /* card end */
  




  /* left right conetent for grid left side image right side content start css */


  .OnlineTP-grid-leftright {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-left: 87px;
    margin-right: 87px;

}

.OnlineTP-image-left {
    max-width: 100%;
    height: auto;
    display: block;
    border: 3px solid #fff; /* Add a white border around the image */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow to the image */
    transition: transform 0.3s; /* Add a transition effect */
    border-radius: 10px;
}

.OnlineTP-image-left:hover {
    transform: scale(1.02); /* Enlarge the image on hover */
}

.OnlineTP-content-right {
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow to the content */
    transition: transform 0.3s; /* Add a transition effect */
    border-radius: 10px;
}

.OnlineTP-content-right:hover {
    transform: scale(1.02); /* Slightly scale up the content on hover */
    background-color: #fcc7c7;
}

.OTPh3{
    font-size: 24px;
    font-weight: 700;
    font-family: 'Didot';
}
.OTPp{
    font-size: 18px;
    font-family: 'Didot';
}
.OTPh4{
    font-size: 22px;
    font-weight: 600;
    font-family: 'Didot';
}


/* left right conetent for grid left side image right side content end css */




/* consulting information css start */


.OTP1 {
    background: #fcc7c7;
  
    padding: 2rem;
    cursor: pointer;
    transition: all 400ms ease-in;
    margin-left: 87px;
    margin-right: 87px;
    margin-bottom: 2rem;
  }

.OTP1_container {
    display: grid;
    
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    transition: all 400ms ease-in;
  }
  
  .OTP1:hover {
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.4);
    z-index: 1;
  
  }
  
  .OTP2 {
    height: 15rem;
  
  }
  
  
  .OTP2para{
    font-weight: 300;
    font-family: 'Didot';
  
  }

  /* consulting information css end */




  /* media query start for online tutor platform  */

  @media only screen and (max-width: 1024px) {

  

    .OnlineTP-container {
      padding-left: 23px;
    }
    .OnlineTP-card-grid {
      margin-left: 23px;
      margin-right: 23px;
    }
    .OTP {
      margin-left: 23px;
      margin-right:23px;
    }
    .OTP1 {
      margin-left:23px;
      margin-right: 23px;
    }
    .OnlineTP-grid-leftright{
        margin-left: 23px;
        margin-right: 23px;
    }
    
    
  
    
  }
  



  @media only screen and (max-width: 825px) {

    .OnlineTP-card-grid {
  
      grid-template-columns: 1fr;
  
    }

    .OnlineTP-grid-leftright{
        grid-template-columns: 1fr;
    }
    
  
    
  
    .OnlineTP-card {
      margin-top: 10px;
    }
  }
  


  @media only screen and (max-width: 767px) {


    .OnlineTP-container {
      padding-left: 21px;
    }
  
    .OnlineTP-card-grid {
  
      margin-right: 21px;
      margin-left: 21px;
  
  
    }
  
    .OTP {
  
      margin-right: 21px;
      margin-left: 21px;
    }
  
    .OTP1 {
      
      margin-right: 21px;
      margin-left: 21px;
    }

    .OnlineTP-grid-leftright{
        margin-right: 21px;
      margin-left: 21px;
    }
  
    
  }
  
  
  
  @media only screen and (max-width: 520px) {


    
  
    .OTP1 {
      height: 25rem;
     
    }

    
  }
  

  @media only screen and (max-width: 420px) {


    
  
    .OTP1 {
      height: 30rem;
     
    }

    
  }

  @media only screen and (max-width: 320px) {


    
  
    .OTP1 {
      height: 35rem;
     
    }

    

    
  }

  @media only screen and (max-width: 285px) {


    
  
    .OTP1 {
      height: 42rem;
     
    }

    

    
  }

  /* media query end for online tutor platform  */



  /*  end for online tutor platform  */





  






