@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Space+Mono&display=swap");

.main-casestudydiv {
  overflow-x: hidden;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.caseStudysection1 {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  background-image: url("/public/Images/case-study-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  font-family: system-ui;
  overflow: hidden;
}

.casestudytitle {
  display: flex;
  height: 100%;
  width: 50%;
  flex-wrap: wrap;
  margin: 15% 10%;
  overflow-y: hidden;
}

.case-headind1 {
  font-size: 50px;
  font-weight: 500;
  font-family: "tungstenw05-medium", Oswald, sans-serif;
  line-height: 50px;

  /* height: 100%; */

  width: 100%;
  color: white;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.case-headind1:hover {
  color: rgb(46, 234, 224);
  text-shadow: 0px 0px 5px rgb(227, 40, 40);
}
.casestudylable {
  overflow: hidden;
  /* position:absolute; */
  display: flex;
  border-bottom-right-radius: 200px;
  display: inline-flex;
  z-index: 1;
  /* margin-left: 10%; */
  flex-wrap: wrap;
  height: 30px;
  margin: 5px;
  padding: 5px;
  width: 150px;
  color: aliceblue;
  background-color: rgb(0, 0, 0);
}

/* media qury for first section of cse study */

@media only screen and (max-width: 329px) {
  html {
    overflow-x: hidden;
  }
  .caseStudysection1 {
    height: auto;
    width: 100%;
    overflow: hidden;
  }
  .case-headind1 {
    font-size: 35px;
    line-height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .casestudytitle {
    width: 100%;
  }
}

@media only screen and (min-width: 330px) and (max-width: 770px) {
  .caseStudysection1 {
    overflow: hidden;
    height: auto;
    width: auto;
    margin: 0px;
    padding: 0px;
  }

  .case-headind1 {
    font-size: 40px;
    line-height: 40px;
    width: 80%;
    height: 100%;
    overflow: hidden;
    /* background-color: #875353; */
  }
  .casestudytitle {
    height: auto;
    width: 100%;
    overflow: hidden;
  }
  .main-casestudydiv {
    overflow: hidden;
  }
}

/* css for form */
.mainformdiv {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.mainformsection {
  display: grid;

  grid-template-columns: 1fr 2fr;
  width: 90%;
  /* margin-left: 10%; */

  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
input::placeholder {
  color: #999;
}
input::placeholder::after {
  content: " *";
  color: red;
}
.formcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  /* background-color: #7b1010; */
  /* padding: 40px; */

  justify-content: center;
  overflow-x: hidden;
  overflow: hidden;
}

/* .case-headind1:hover {
   
  }
    */
.form-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
  height: auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.formfeild {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.formleftcontent {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
  margin-top: -35%;

  /* background-color: #875353; */
}

.leftformtitle {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px;
  font-size: 50px;
  line-height: 50px;
  font-weight: 500px;
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-left: -10%; */
}
.leftformtexts {
  display: flex;
  font-size: 20px;
  line-height: 25px;
  /* margin-top: -40%; */
  padding: 30px;
  font-family: system-ui;
  height: auto;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 10px;
  height: 80px;
  margin-top: 5px;
  outline: none;
  border: none;
  border-bottom: 3px solid #000000;
  box-sizing: border-box;
}
.inputfeild:hover {
  border-bottom: 2px solid rgb(195, 21, 21);
}

textarea {
  height: 120px;
  width: 100%;
  margin-bottom: 5px;
}

.form-button[type="submit"] {
  width: 150px;
  height: 50px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.form-button[type="submit"]:hover {
  background-color: #0056b3;
}
.form-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: system-ui;
  justify-content: center;
  font-size: 40px;
  overflow: hidden;
}
.buttondiv {
  display: flex;
  flex-wrap: wrap;

  margin-top: 5%;
}

@media only screen and (max-width: 768px) {
  .mainformdiv {
    height: auto;
  }

  .mainformsection,
  .form-group {
    margin: 20px;
    height: auto;
    grid-template-columns: 1fr;
    margin-left: 0px;
  }

  .formleftcontent {
    /* height: auto; */
    width: 100%;
    margin: 0px;
    padding: 0px;
    /* margin-top: -40%; */
    border-radius: 20%;
  }
  .leftformtitle {
    padding: 0px;
    margin: 20px;
  }
  .leftformtext {
    margin: 0px;
    padding: 0px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1200px) {
}

.part2 {
  font-family: system-ui;
  background-image: url("/public/Images/images.jpg");
  /* display: grid; */
  align-items: center;
  justify-content: center;
  background-size: "cover";
  background-position: "center";
  background-repeat: "no-repeat";
  width: "100vw";
  height: "100vh";
}

.para-in {
  position: relative;
  padding-bottom: 75px;
}

.case-pt75 {
  padding-top: 75px;
}

.case-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  width: 74%;
}

@media only screen and (max-width: 768px) {
  .case-container {
    width: 95%;
  }
}

.row-k {
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (max-width: 768px) {
  .row-k {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 396px) {
  .case-headind1 {
    font-size: 30px;
  }
}

article {
  display: block;
}

.row-in {
  width: 83.33%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.case-mb20 {
  margin-bottom: 20px;
}

.part2-para {
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.case-mt65 {
  margin-top: 65px;
}

.row-in2 {
  padding: 0 15px;
}

p {
  font-size: 18px;
  margin: 0 0 10px;
}

.pad-all,
.align-box {
  padding: 50px;
}

.yellow-bg {
  background-color: #ffe03f;
}

ul {
  margin-top: 0;
  margin-bottom: 10px;
  list-style: none;
  padding: 0;
  font-weight: 300;
  font-size: 18px;
}

ul.-list-items li {
  display: block;
  padding-left: 20px;
}

ul.case-list-items li {
  display: block;
  padding-left: 20px;
  background-image: url("/public/Images/right-arrow-blue.svg");
  background-repeat: no-repeat;
  background-position: 0 8px;
}

li {
  margin-bottom: 14px;
}

.row1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
}

@media only screen and (max-width: 768px) {
  .row1 {
    grid-template-columns: 1fr;
  }
}
.part-3 {
  font-family: system-ui;
}
.pdf {
  border: 2px solid #000;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 1.5px;
  font-weight: 700;
  margin-top: 30px;
  display: inline-block;
  text-decoration: none;
}

strong {
  font-weight: 600 !important;
}

.h2-heading-cs {
  font-size: 70px;
  font-weight: 500;
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  line-height: 70px;
  display: block;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #000;
}

.text-center {
  text-align: center;
}

.para-txt {
  font-size: 40px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 40px;
  font-family: system-ui;
}

.case-mb50 {
  margin-bottom: 50px !important;
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .case-mb50 {
    width: 95%;
  }
}
.light-gray {
  color: #7e7e7e !important;
}

.head-case {
  margin-top: 0;
  font-size: 35px;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: center;
  font-family: system-ui;
}

.case-mt45 {
  margin-top: 45px;
}

.case-pt0 {
  padding: 0;
}

.case-pb75 {
  padding-bottom: 75px;
}

@media (min-width: 992px) {
  .col-md-part-2 {
    margin-left: 16.66666667%;
  }
}
.pd-lr {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .para-txt {
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
  }
}

@media screen and (max-width: 768px) {
  .h2-heading {
    font-size: 40px;
    line-height: 40px;
  }
}
@media (min-width: 992px) {
  .pd-lr {
    width: 66.66666667%;
  }
}

@media (min-width: 992px) {
  .col-part1 {
    margin-left: 6.33333333%;

    padding-right: 10px;
  }
}

.part4 {
  /* display: grid; */
  align-items: center;
  justify-content: center;
  background-size: "cover";
  background-position: "center";
  background-repeat: "no-repeat";
  width: "100vw";
  height: "100vh";
  font-family: system-ui;
}

.part4-row1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

@media only screen and (max-width: 768px) {
  .part4-row1 {
    grid-template-columns: 1fr;
  }
}
