.card {
  margin: 10px;
  background-color: rgb(211, 227, 245);
}

.box {
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(228, 239, 243);
}

#box1 {
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: 70px;
  margin-right: 70px;
  border-radius: 30px;
}

.d {

  font-family: myriad-pro, Arial;
  font-size: 18px;
  font-weight: 300;
  color: black;
  box-sizing: border-box;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1090px;
  border-radius: 20px;
  height: 400px;
  padding: 0;
  margin-top: 100px;
  background-image: url('/public/Images/audit.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.s {
  background-color: rgb(239, 239, 239);
  height: 120px;
  border-radius: 5px;
  margin-top: 100px;
  margin-bottom: 5px;


}