.deposite-heading{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 170px;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;

}
.deposite-para{
    padding-left: 170px;
    font-size: 21px; 
}