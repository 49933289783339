.customer-heading{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 170px;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
}
.customer-parag{
   padding-left: 170px;
    font-size: 21px;
}
.imagcustomer{
   margin-left: 170px;
   margin-right: 100px;
    width: 870px;
    height: 390px;
    margin-bottom: 30px; 
}
/* CSS for the feature table container */
.feature-table {
    flex-wrap: wrap; /* Allow boxes to wrap to the next row */
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px; /* Adjust the maximum width as needed */
    margin: 0 auto;
    
  }
  
  /* CSS for each feature box */
  
    .feature-boxs {
        display: flex;
        justify-content: center; 
        align-items: center; 
        height: 100vh;
      }
      
     
      
  
  
 /* CSS for the inner box */
.box {
    border: 1px solid #ccc;
   margin-left: 40px;
    text-align: center;
    transition: transform 0.2s; /* Add a transition for the zoom effect */
    font-size: 1.2rem; /* Increase the font size of text */
  }
  
  /* CSS for the icons */
  .box i {
    font-size: 2rem; /* Increase the font size of icons */
  }
  
  /* Hover effect: zoom in on hover */
  .box:hover {
    transform: scale(1.1); /* Zoom in */
  }
  
  
  
  /* Media query for smaller screens to create a 1-column layout */
  @media (max-width: 767px) {
    .feature-box {
      margin-left: 15px;
      width: 90%; /* Full width for smaller screens */
    }
    .api{
      overflow-y: hidden;
    }
    .customer-heading{
      padding-left: 5px;
    }
    .customer-parag{
      padding-left: 5px;
    }
    .imagcustomer{
      margin-left: 5px;
      margin-right: 5px;
    }
    .feature-table{
      overflow-x: hidden;
    }
    .box{
      margin-right: -10px;
      margin-left: 5px;
    }
  }
  
  