.mobile-heading{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 7%;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
}

  
  h1 {
    font-weight: bold;
    padding-top: 30px;
    padding-left: 30px;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .para-heading{
    padding-left: 30px;
  }

 /* Style for the carousel container */
.carousel-inner {
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 40px;
    text-align: center; /* Center align the content */
  }
  
  /* Style for the carousel item */
  .carousel-item {
    position: relative; /* Create a stacking context for child elements */
  }
  
  /* Style for the carousel image */
  .carousel-item img {
    max-width: 100%;
    height: 570px /* Make the image responsive */
  }
  
  /* Style for the carousel caption */
  .carousel-caption {
   
    color: #fff; /* Text color */
    position: absolute;
    top: 50%; /* Position vertically centered */
    left: 50%; /* Position horizontally centered */
    transform: translate(-50%, -50%); /* Center the caption */
    padding: 10px; /* Add some padding to the caption */
    border-radius: 5px; /* Rounded corners for the caption */
    width: 80%; /* Adjust the width as needed */
  }
  
  /* Style for the caption headings */
  /* Style for the caption headings */
.carousel-caption h3 {
    font-size: 30px;
  margin-bottom: 5px; /* Reduce the margin at the bottom */
  font-weight: bold;
  }
  
  
  /* Style for the caption paragraphs */
  .carousel-caption p {
    color: rgb(255, 255, 255);
    font-size: 26px;
    margin: 10px 0;
  }
  
  /* Style for the caption lists */
  .carousel-caption ul {
    text-align: left;
    margin-left: 20px;
  }
  
  /* Style for the caption list items */
  .carousel-caption li {
    color: white;
    margin: 5px 0;
    
  }
 /* Style for the card container */
.cart {
   background-image: url('/public/Images/mobile10.png');
    margin-left: 30px;
    margin-right: 30px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  /* Style for the headings within the card */
  .cart .headings-point {
    font-family: tungstenw05-medium, Oswald, sans-serif;
    font-size: 34px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Style for the list items within the card */
  .cart .li-ul {
    padding-top: 20px;
    margin-bottom: 8px;
    text-align: left;
  
  }
  .pare{
    padding-top: 30px;
    padding-left: 30px;
    margin-bottom: 20px;
  }
  
  /* Add more styles as needed to match your design */
  /* For screens with a maximum width of 767px (typical mobile view) */
@media (max-width: 767px) {
  .carousel-item img {
   
    max-width: 100%; /* Ensure the image stays within the container */
  }
  .mobile-heading{
    padding-left: 5px;
  }
}
