.customeron-heading{
    background-color: purple;
     font-size: 32px;
     width: 100%;
     height: 220px;
     padding-left: 7%;
     padding-top: 100px;
     color: rgb(254, 253, 253);
     font-weight: bold;
     font-family: 'Oswald', sans-serif;

     @media (max-width: 768px){
      padding-left: 5px;
     }
     
     
 }
 .customeronboradingimges{
   
   margin-right: 20px;
   margin-top: -100px;
  float: right;
     max-width: 400px; /* Adjust the image width as needed */
     max-height: 400px; /* Adjust the image height as needed */
 }
 /* Hide the image for mobile view */
@media (max-width: 768px) {
  .customeronboradingimges {
    display: none;
  }
}


 .two-column-container1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Adjust the gap between cards as needed */
 margin-left: 20px;
 margin-top: 30px;
  /* Responsive layout for smaller screens */
  @media (max-width: 768px)
  {
    grid-template-columns: 1fr; 
    width: 100%;
  padding-left: -3px;
  padding-right: 3px;
    
  }
}
 
 .column2 {
   /* flex: 0 1 calc(50% - 20px);  */
  /* margin-left: 100px;
  margin-top: 40px;
  margin-bottom: 20px;
  max-width: 490px; */
    border: 1px solid #ccc;
   box-sizing: border-box; /* Include padding and border in the element's total width/height */


   @media (max-width: 768px) {
   
    width: 100%;
    padding-right: -3px;/* Switch to a single column layout for screens <= 768px wide */
  
  }
}
 
 
 .customerkycimg {
   /* max-width: 100%;
   max-height: 100%;
   padding-top: 40px; */
   /* width: auto;
   height: auto; */
 }
 
 
 
   /* CSS for kyc-icon class */
   .kyc-icon {
     font-size: 37px;
     color: #080808;
      /* margin-right: 10px;
     padding-top: 5px;  */
     vertical-align: middle; /* Vertically align the icon */
   }

   

   
   .customerkyc-heading {
     /* padding-top: 20px; */
     display: inline-block; /* Make the heading and icon inline-block elements */
     vertical-align: middle; /* Vertically align the heading */
     /* Remove any default margin to ensure proper alignment */
   }
   
   /* Additional CSS for .customerkyc-para and other styles */
   
   