.not-found {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .not-found h1 {
    font-size: 36px;
  }
  
  .not-found p {
    font-size: 18px;
    margin-top: 10px;
  }
  