/* SocialMediaDevelopment.css */

/* Styles for social-media-app-container */
.social-media-app-container {
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 7%;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
  }
  
  .social-media-heading {
    padding-top: 20px;
    padding-left: 30px; /* Adjust the padding as needed */
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .section-description {
    font-size: 16px;
    color: #000000; /* Change the text color to your preference */
  }
  .main-description{
    background-image: url('/public/Images/social1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
  }
  
  .we{
    color: white;
    padding-left: 20px;
    font-size:30px ;
   font-weight: bold;
   text-align: center;
   margin-right: 20px;
   font-family: tungstenw05-medium, Oswald, sans-serif;
  }
  .de{
    color: white;
    padding-left: 20px;
    font-size:16px ;
    padding-top: -50px;
    font-family: myriad-pro, Arial;
  }
  .listing{
    
    width: 100%;
    height: auto;
    padding-left: 20px;

  }
  .li{
    color: white;
  }
  

  .zigzag-container {
    /* display: flex;
    flex-direction: column; */
    /* padding: 20px; */
  }
  
  .AI {
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 20px;
  }
  
 /* Apply a grid layout */
.sd-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Gap between cards */
}

/* Style for the card */
.sd-card {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-left: 30px;
  margin-right: 30px;
}

/* Hover effect for the card */
.sd-card:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Style for the card content */
.sd-card-content {
  text-align: left;
}

/* Additional styling for headings and paragraphs inside the card */
.sd-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.sd-card p {
  font-size: 1rem;
}

  
  @media (max-width: 768px) {
  .sd-grid-container{
    grid-template-columns: 1fr;
    
  }
.social-media-app-container{

}


  }
  /* WhyChooseUs.css */

.why-choose-us-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px;
    background-color: #f0f0f0;
    text-align: center;
  }
  .why{
    font-family: tungstenw05-medium, Oswald, sans-serif;
    font-size: 34px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;margin-bottom: 20px;
  }
  
  .why-choose-us-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }
  
  .why-choose-us-list li {
    margin-bottom: 10px;
  }
  
  .why-choose-us-list strong {
    font-weight: bold;
  }
  
  .call-to-action {
    margin-top: 20px;
  }
  