
.k{
    font-family: myriad-pro, Arial;
    margin-top: 20px;

}
.mngmt-profile{
    font-size: 37px;
    margin-top: 58px;
}

#founder{
    font-size: 20px;
    font-family: myriad-pro, Arial;
    
}
@media only screen and (max-width: 767px) {

  
     .k{
        margin-right: 10px;
        padding-right: 68px;
        padding-left: -1px;
    }
    #founder{
        font-size: 11px;
        
    }
    #main-text-body{
    margin-top: 0 !important;
    }
  
  }
 