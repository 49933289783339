

 /* Container with margin and padding */
 .Reporting-clearfix{
margin-top: 60px;
margin-bottom: 60px;
margin-left: 87px;
margin-right: 87px;
 }
  /* Video wrapper styles */
  .Reporting-promo-video-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Dark background color */
  .bg-sapphire-dark {
    background-color: #1c2237;
    color: #fff;
  }
  
  /* Lazy-loaded image */
  .Reporting-promo-img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Image overlay */
  .Reporting-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Button to explore on YouTube */
  .Reporting-image-button {
    padding: 10px 20px;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .Reporting-image-button:hover {
    background-color: #c0392b;
  }
  
  /* Text inside the overlay */
  .Reporting-image-text {
    color: #fff;
    font-size: 24px;
    margin-top: 20px;
  }
  
  /* Animation for the overlay */
  .Reporting-promo-video-wrapper:hover .Reporting-image-overlay {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Animation for the entire video wrapper */
  .Reporting-promo-video-wrapper {
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease-in-out forwards;
  }
  
  @keyframes fadeInUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .Reporting-promo-video-wrapper {
      border-radius: 0;
    }

    .Reporting-clearfix{
        
        margin-left: 25px;
        margin-right: 25px;
         }
  }


  /* Additional CSS for screens under 400px */
@media (max-width: 420px) {
    .Reporting-promo-video-wrapper {
      border-radius: 0;
      box-shadow: none;
    }
  
    .Reporting-image-overlay {
      background: rgba(0, 0, 0, 0.8);
    }
  
    .Reporting-image-button {
      padding: 8px 16px;
      font-size: 16px;
    }
  
    .Reporting-image-text {
      font-size: 18px;
    }
  }
  

  @media (max-width: 300px) {
    .Reporting-image-button {
      padding: 6px 12px;
      font-size: 14px;
    }
  
    .Reporting-image-text {
      font-size: 16px;
    }
  }
  
  
  
  
