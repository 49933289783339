.containeree {
    text-align: center;
  }
  
  .columnsn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
 
/* Add this CSS to create a gap between columns */
.columnq {
  width: calc(25% - 20px); /* Adjust the width and margin as needed */
  padding: 20px;
  height: 150px;
  background-color: #e6e6e6;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  transition: transform 0.3s ease;
  margin-right: 20px; /* Add margin-right to create a gap between columns */
}

/* Remove margin-right for the last column in each row */
.columnq:nth-child(4n) {
  margin-right: 0;
}

  .columnq:hover {
    transform: scale(1.1); /* Increase the scale on hover (zoom in) */
  }
  
  
  .columnq h3 {
    text-align: center;
    font-size: 18px;
    color: #1f1f1f;
  }
  
  .columnq p {
    font-size: 14px;
    color: #222222;
    text-align: center;
  }
  
  
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
 /* Media query for responsive layout */
@media (max-width: 768px) {
  .containeree {
    text-align: center;
  }

  .columnsn {
    display: flex;
    flex-wrap: wrap; /* Ensure that columns wrap to the next line */
    justify-content: center; /* Center columns horizontally */
    margin-top: 20px;
  }

  .columnq {
    width: calc(50% - 20px); /* Adjust the width and margin as needed for mobile view */
    margin-right: 0; /* Remove margin-right to stack columns vertically */
  }
}
