.AIheadings {
  font-size: 26px;
  color: black;
  margin-top: -70px;
  padding-bottom: 20px;

}

.AIcard {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  height: 330px;
}


.AIcard:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.carousel-caption {
  height: 100%;
}

.carousel-caption p {
  font-size: 18px;
  line-height: 1.5;
  color: black;
  padding-bottom: 20px;
}


.row .col-md-3 {
  text-align: center;
  margin-bottom: 20px;
}

.icon {
  background-color: #007bff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto 20px;
}

.icon i {
  font-size: 26px;
  color: #fff;
}

.ai-headingsss {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}


.ai-paragraph {
  font-size: 16px;
  line-height: 1.5;
}