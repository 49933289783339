/* DropdownMenu.css */

.dropdown {
  position: relative;
  display: inline-block;
  font-family: system-ui;
}
.ok {
  color: rgb(252, 248, 248);
  text-decoration: none;
  font-family: system-ui;
}
.ok:hover {
  text-decoration: underline;
}

.dropdown-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  height: auto;
  width: 100vw;
  margin-left: -32vw;
  margin-right: -33vw;

  /* padding-left: 60px; */
  z-index: 1;
}

.dropdown-content-2 {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* height: 60vh; */
  width: 100vw;
  margin-left: -40vw;
  padding-top: 10px;

  /* padding-left: 60px; */
  z-index: 1;
}

.dropdown-content-3 {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  height: 60vh;
  width: 100vw;
  margin-left: -48vw;
  padding-top: 10px;

  padding-left: 10px;
  z-index: 1;
}

.dropdown-content-4 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  height: 60vh;
  width: 100vw;
  margin-left: -73.5vw;
  padding-top: 10px;
  /* margin-top: 29px; */
  padding-left: 60px;
  z-index: 1;
}
.dard1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.link-aj {
  text-decoration: none;
  display: grid;
  font-family: system-ui;
  margin-bottom: 10px;
  color: black;
}

.link-aj:hover {
  color: rgb(246, 129, 4);
}

.dropdown-content-2 p {
  padding: 12px 16px;
  margin: 0;
  cursor: pointer;
}

.dropdown-content-3 p {
  padding: 12px 16px;
  margin: 0;
  cursor: pointer;
}
.dropdown-content-4 p {
  padding: 12px 16px;
  margin: 0;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content-2 {
  display: block;
}

.dropdown:hover .dropdown-content-3 {
  display: block;
}

.dropdown:hover .dropdown-content-4 {
  display: block;
}

.left-aj {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.allplatforms {
  font-family: system-ui;
  color: black;
  margin-top: 50px;
  margin-left: 20px;
  margin-bottom: 20px;
  color: black;
}

.linehr {
  width: 90%;
  color: black;
}
/* case study css */

.dropdown-5 {
  position: relative;
  display: inline-block;
  font-family: system-ui;
}
.ok-5 {
  color: rgb(252, 248, 248);
  text-decoration: none;
  font-family: system-ui;
}
.ok-5:hover {
  text-decoration: underline;
}

.dropdown-btn-5 {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.dropdown-content-5 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  height: 60vh;
  width: 100vw;
  margin-left: -52vw;
  margin-right: -53vw;

  padding-left: 60px;
  z-index: 1;
}

.dard-5 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.link-aj-5 {
  text-decoration: none;
  display: grid;
  font-family: system-ui;
  margin-bottom: 10px;
  color: black;
}

.link-aj-5:hover {
  color: rgb(246, 129, 4);
}

.dropdown-content-5 p {
  padding: 12px 16px;
  margin: 0;
  cursor: pointer;
}

.dropdown-5:hover .dropdown-content-5 {
  display: block;
}

.left-aj-5 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.allplatforms-5 {
  font-family: system-ui;
  color: black;
  margin-top: 50px;
  margin-left: 20px;
  margin-bottom: 20px;
  color: black;
}

.linehr-5 {
  width: 90%;
  color: black;
}

.card-pr10p{
  padding-right: 6%;
}

@media only screen and (min-width:1250px){
  .card-pr10p{
    padding-right: 10%;
  }
}

@media only screen and (min-width:1200px){
  .dropdown-content-2{
    margin-left: -45vw;
  }
}