#technicala{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    margin-left: -3%;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
}
@media (max-width: 500px) {
   .technicaldoucumentss{
    text-align: left;
   }
   #technicala{
    margin-left: 10px;
    width: 100%;
    height: max-content;
   }
   #technicalwht{
    text-align: left;
   }
   .small-image{
    margin-top: 30px;
    padding-right: 30px;
   }
  }
  