p{
    font-weight: 500 !important;
    margin: 0 0 10px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: myriad-pro, Arial;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
    
    
}
.h3{
    font-weight: 900;
    margin: 10px 0;
}
li{
    font-weight: 500 !important;
    margin: 0 0 10px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: myriad-pro, Arial;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
}
@media only screen and (max-width: 768px) {

  .h2-heading {
    padding-left: 5px;
    font-size: 24px; /* Adjust the font size as needed for mobile */
    text-align: left; /* Center-align the text */
    color: #ffffff; /* Set the text color to white */
}
    .pt75 {
      text-align: left;
      font-size: 16px;
      line-height: 1.5;
      padding-left: 20px;
      
    }
  
    .h3 {
      font-size: 24px;
    }
  }