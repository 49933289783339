
.cybersecurity-heading{
  background-color: purple;
  font-size: 32px;
  width: 100%;
  height: 220px;
  padding-left: 32px;
  padding-top: 100px;
  color: white;
  font-family: 'Oswald', sans-serif;
}
.heading{
  padding-left: 30px;
}
.heading-h3s{
  padding-left: 30px;
  font-size: 20px;
  color: black;
  font-family: Arial, sans-serif; /* Use your preferred font stack */
}
.pw {
  font-family: Arial, sans-serif; /* Use your preferred font stack */
  font-size: 18px; /* Adjust the font size as needed */
  line-height: 1.5; /* Adjust line height for readability */
  color: #333; /* Text color */
  margin-bottom: 20px; /* Add space below the paragraph */
  padding-left: 30px;
}
.para{
 
  padding-left: 20px;
  font-size: 16px;
   margin: 0;
}
.h4{
  font-weight: bold;
  padding-left: 40px;
  
}

.two-column-lists {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* Make the list items display in a row */
  flex-wrap: wrap; /* Allow items to wrap to the next row if needed */
  justify-content: space-between; /* Distribute items evenly between columns */
}

.two-column-lists li {
  width: calc(50% - 10px); /* Set the width of each column (adjust as needed) */
  margin-bottom: 20px; /* Adjust the spacing between items */
  box-sizing: border-box; /* Include padding and borders in the width calculation */
}
.h5-heading{
  font-weight: bold;
  padding-left: 20px;
  color: black;
  font-size: 18px;
  margin: 0;
}

.d{
 
  font-family: myriad-pro, Arial;
  font-size: 18px;
  font-weight: 300;
  color: black;
  box-sizing: border-box;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1090px;
  border-radius: 20px;
  height: 400px;
  padding: 0;
  margin-top: 100px;
  background-image: url("https://uploads-ssl.webflow.com/63b58a1d0779749fb7113edd/640b1ece4af92c3db1e4f227_security-audit.png");
  background-size: cover;
background-repeat: no-repeat;
background-position: center;
}
.heading-h4{
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  padding-top: 40px;

}
ol, ul {
  list-style: none;
  padding-left: 0;
}

 li {
  margin-bottom: 20px;
  padding-left: 30px;
 
}

ul li::before {
 
  margin-right: 10px;
}
.r{
  padding-top: 100px;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
}

/* Style the list as a horizontal (inline) list */
.fa-icon-list-horizontal {
  list-style: none;
 padding-left: 40px;
  display: inline; /* Display list items horizontally */
}

.fa-icon-list-horizontal li {
  display: inline-block; /* Display list items in a row */
  margin-right: 20px; /* Add space between list items (adjust as needed) */
}

/* Style the icons */
.section-icon1 {
  font-size: 24px;
  margin-right: 10px;
  color:black;
}
.section-icon11{
  padding-left: 40px;
  font-size: 24px;
  margin-right: 10px;
  color:black;
}
/* Style the text */
.fa-icon-list-horizontal li {
  font-size: 16px;
  color: #333;
}

.p1{
  padding-left: 30px;
  padding-top: 40px;
}
.s{
  background-color: rgb(239, 239, 239);
  height: 120px;
  border-radius: 5px;
  margin-top: 100px;
  margin-bottom: 5px;

 
}




/* new code */


.cyber-faqs {
  text-align: center;
  margin-left: 67px;
  margin-right: 67px;
  padding: 20px;
}

.cyber-faq {
  background-color: #fcc7c7;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  /* max-width: 800px; */
  /* margin: 0 auto; */
  transition: transform 0.3s ease;
}

.cyber-faq:hover {
  transform: scale(1.02);
}

.cyber-question_answer {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

ul.fa-icon-list-horizontal {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

ul.fa-icon-list-horizontal li {
  display: flex;
  align-items: center;
  animation: slideIn 0.5s ease-in-out;
  margin: 10px;
  max-width: 300px;
  flex-basis: calc(33.33% - 20px);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  background-color: #fcc7c7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@keyframes slideIn {
  0% {
      opacity: 0;
      transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  ul.fa-icon-list-horizontal li {
      flex-basis: calc(50% - 20px);
  }

  .cyber-faqs {
   margin-left: 6px;
   margin-right: 6px;
}
}


