.screen {
  height: 100vh !important;
}

/* Style for links that should always be white */
.nav-link span.white-item {
  color: #fff;
  font-weight: bolder;
}
.okchange {
  color: #ccc !important;
}

h2 {
  height: fit-content !important;
}

/* .line{
 
 
    background-color: #fff ;
    font-weight: bolder !important;
    width:50px;
   
    opacity: 1;
   
    height: 2px;
    transition: transform 1s ease-in}
  */

/* add new code just for animations */

/* Add these styles to your existing stylesheet or create a new one */

/* @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



.home-second-heading,
.fullpage-desc,
.fullpage-second-desc {
  opacity: 0;
  animation: fadeInUp 1s forwards;
  
}

.home-second-heading {
  animation-delay: 0.3s;
}

.fullpage-desc {
  animation-delay: 0.6s;
}

.fullpage-second-desc {
  animation-delay: 0.9s;
}

  */

/* button new changes start */

.atul-button {
  border: 1px solid black;
  border-radius: 1px;
  width: 175px;
  height: 50px;
  display: block;
  margin-top: 40px;
  background: linear-gradient(to right, black 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  text-decoration: none;
}

.atul-button:hover {
  background-position: left bottom;
}

.atul-text {
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  color: black;
  transition: all 0.6s ease-out;
  display: block;
}

.atul-text:hover {
  color: white;
}

/* button new changes end */

/* nav-bar new changes start*/

.newfontchange {
  font-family: system-ui;
}

/* nav-bar new changes end*/

.line {
  /* background-color: #000000; */
  /* width: 50px; */
  content: "";
  background-color: #fff;
  display: block;
  opacity: 1;
  width: 30px;
  height: 1px;
  transition: all 0.3s ease-in-out;
}

/* .nav-link span {
 
 
  color: white;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  padding-top: 5px;
  font-size: 14px;
} */

.nav-link span {
  /* color: #000000;
  color: black; */

  color: #fff;
  opacity: 1;
  transition: all 0.3s ease-out;
  padding-top: 5px;
  font-size: 14px;
}

nav {
  color: #fff;
}

.nav-link:hover span {
  display: initial;
  /* transform: scale(1.3); */
}

.nav-link:hover .line {
  width: 60px;
  height: 4px;
  transition: all 0.3s ease-out;
  opacity: 1;
}

.active span {
  display: initial;
  font-weight: bolder !important;
  /* transform: scale(1.3); */
}

.active .line {
  width: 60px;
  height: 4px;
  transition: all 0.3s ease-out;
  opacity: 1;
}

.active .line::before {
  width: 60px;
  height: 4px;
  /* transform: scale(1.3); */
  opacity: 1;
  transition: all 0.3s ease-out;
}

.nav-link {
  z-index: 100;
}

span {
  display: none;
}
.text {
  color: white;
  font-size: 2.5rem;
  position: relative;
  vertical-align: middle;
  justify-content: center;
  padding: 300px;
}
.text .btn {
  color: white;
}

/* Default styling for larger screens */
#items-1 {
  background-attachment: fixed;
  background-image: url("/public/Images/generative-ai-lead.png");
  /* background-image: url("/public/Images/championsss-home.jpg"); */
  /* background-color: rgb(178, 224, 238); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

@media (max-width: 1500px) {
  #items-1 {
    background-attachment: scroll;

    background-image: url("/public/Images/wave.svg");
    background-color: rgb(178, 224, 238);
    /* background-image: url("/public/Images/championsss-home.jpg") ; */
    background-color: rgb(247, 133, 33);
    background-repeat: no-repeat;
  }
}

@media (min-width: 1200px) {
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-6 {
    float: left;
  }
}
.col-lg-6,
.col-sm-6,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  margin-top: -27px;
}

.text .btn {
  color: white;
}

.btn:hover {
  border-color: #000000;
  background-color: #000;
}

@media (min-width: 1200px) {
  .col-lg-6 {
    width: 50%;
  }

  .col-lg-6 {
    float: left;
  }
}

.col-lg-6,
.col-sm-6,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

div {
  display: block;
}

style attribute {
  visibility: visible;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

body {
  font-family: myriad-pro, Arial;
  font-size: 18px;
  font-weight: 300;
  color: #000000;
  padding: 0;
  margin: 0;
}

.scroll-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: white;
}

#item-1 {
  padding-top: 100px !important;
  padding-left: 7% !important;
  color: #aaa;
  font-weight: bolder;
}
#item-2 {
  color: #ffffff;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 40px;
}
.home-second-heading {
  padding-top: 50px;
  font-size: 80px;
  font-family: "Oswald", sans-serif;

  font-weight: 500;
}

.fullpage-desc {
  /* padding-bottom: 20px; */
  font-family: system-ui;
}

.fullpage-second-desc {
  font-family: system-ui;

  font-size: 21px;
  line-height: 30px;
  font-weight: normal;
}

#heading {
  padding-left: 0 !important;
  text-align: left !important;

  font-size: 65px;
  line-height: 80px;
  /* font-family: Times New Roman, Times, serif; */
  font-family: "Oswald", sans-serif;
  color: white;

  font-weight: 500;
  padding-top: 0px;
  padding-top: 0px !important;
}
#productserviceheading {
  font-size: 70px;
  line-height: 80px;
  font-family: "Oswald", sans-serif;
  color: black;
  padding-top: 0;
  font-weight: 500;
  margin-top: 20px;
  padding-bottom: 20px;
}
#Bharatmake-title {
  font-size: 70px;
  line-height: 80px;
  font-family: "Oswald", sans-serif;
  color: black;
}

/* Add styles for the horizontal line */
.linee {
  width: 100%; /* Adjust the width as needed */
  border-top: 2px solid #f46c32; /* Adjust the color and thickness as needed */
  margin-top: 20px; /* Adjust the spacing as needed */
}

.aboutus-grid-title {
  font-size: 50px !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  font-family: myriad-pro, Arial;
  font-weight: bold !important;
}
#our-purpose-bharatmake-para {
  font-family: myriad-pro, Arial;
  font-size: 25px !important;
  color: #7e7e7e !important;
}
#Bharatmake-title {
  font-size: 70px;
  line-height: 80px;
  font-family: "Oswald", sans-serif;
  color: black;
}

/* Add styles for the horizontal line */
.linee {
  width: 100%; /* Adjust the width as needed */
  border-top: 2px solid #f46c32; /* Adjust the color and thickness as needed */
  margin-top: 20px; /* Adjust the spacing as needed */
}

.aboutus-grid-title {
  font-size: 50px !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  font-family: myriad-pro, Arial;
  font-weight: bold !important;
}
#our-purpose-bharatmake-para {
  font-family: myriad-pro, Arial;
  font-size: 25px !important;
  color: #7e7e7e !important;
}
#item-5 {
  height: auto !important;
  color: white;
  padding-right: 15px;
  /* padding-right: 15px; */

  margin-right: auto;
  margin-left: auto;
  /* padding-bottom: 50px; */
  /* padding-bottom: 50px !important; */
  margin-top: 0 !important;
  padding-top: 60px;
}
/* .d-flex {
 
  align-items: flex-start !important ;
  flex-wrap: wrap !important;
  margin: -15px !important;
 
} */

/* Media query for screens with a maximum width of 768px (mobile devices) */
@media (max-width: 768px) {
  /* Hide the background content when the sidebar is open on mobile devices */
  .sidebar #iteam5 {
    display: none;
  }
}

.italic {
  font-style: italic;
  font-size: 20px;
  font-family: myriad-pro, Arial;
  color: #fff;
}

.purpose {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  font-family: myriad-pro, Arial;
  color: #fff;
}

#item-3 {
  color: #ffffff;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 0 !important;
  padding-top: 60px !important;
}
/* Default styles for the image */
.fullCoverImg-left {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin-bottom: 30px;
}

/* Media query for screens with a maximum width of 768px (mobile view) */
@media screen and (max-width: 768px) {
  .fullCoverImg-left {
    background-position: left;
  }
}
@media only screen and (max-width: 545px) {
  .agile-digital-bg-img {
    margin-left: -10%; /* Adjust the margin to center the image */
    margin-right: -10%;
    overflow-x: hidden;

    background-size: 190%; /* Increase the size of the background image */
  }
  #How {
    margin-top: -500px;
  }
}

@media only screen and (max-width: 600px) {
  .agile-digital-bg-img {
    margin-top: 0;
  }
}

#item-4 {
  height: 100vh !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;

  color: #ffffff;
}

#item-5 {
  /* padding-right: 15px; */

  /* padding-right: 15px; */

  /* padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin: 0;
}

.col-md-6 {
  padding: 0;
}

.para {
  font-size: 40px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 40px;
}

.fig-small {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  /* Adjust styles for screens up to 768px wide */
  .col-md-9.float-center {
    padding: 0;
  }
  .col-md-9.float-center .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#item-6 {
  height: fit-content !important;
  /* width: 100vw !important; */
  padding-top: 20px !important;
}
#item-7 {
  height: fit-content !important;
  margin-top: 50px;
}
/* item-7 */
#item-7-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  max-width: 1500px !important;
}

#bharatmake-initiative {
  padding-top: 40px;
}
#The-pharmacist-store-heading {
  font-size: 70px;

  font-family: "Oswald", sans-serif;
  color: black;
  padding-top: 40px;
}
#The-pharmacist-store {
  font-size: 25px;
  font-family: myriad-pro, Arial;
  padding-top: 40px;
}

.title-sml {
  font-size: 24px;
  margin-bottom: 10px;
}

.para-ovr {
  font-weight: 600;
  font-family: "Didot";
  padding-top: 0;
}
.row-home .aboutus-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 768px) {
  #item-7-box {
    margin-top: 0px !important;
  }
  .our-purpose {
    margin-top: 0 !important;
  }
  .title-sml {
    font-size: 26px !important;
    font-weight: 500 !important;
    font-family: tungstenw05-medium, Oswald, sans-serif !important;
  }
  .our-purpose h2.title-sml {
    font-size: 40px !important;
    font-weight: 500 !important;
    font-family: tungstenw05-medium, Oswald, sans-serif !important;
  }
  .para-ovr {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  #h2-heading {
    font-size: 40px !important;
    font-weight: 500 !important;
    font-family: tungstenw05-medium, Oswald, sans-serif !important;
  }
  #The-pharmacist-store {
    font-size: 25px !important;
    line-height: 30px !important;
  }
}

@media (max-width: 576px) {
  .title-sml {
    font-size: 18px;
  }
}
.our-purpose {
  padding: 50px;

  border: 3px solid #92d1ff;
  position: relative;
  margin-top: 30px;
}

.our-purpose h2.title-sml {
  letter-spacing: 2px;
  /* margin-top: 0; */
  margin-bottom: 0;

  line-height: 0.9;
  font-size: 70px;
  font-weight: 500;
  font-family: tungstenw05-medium, Oswald, sans-serif;
}
.our-purpose .para-ovr {
  line-height: 36px;
}
.our-purpose::after {
  bottom: -23px;
  left: -23px;
  transform: rotate(135deg);
}
.our-purpose:after,
.our-purpose:before {
  content: "";
  position: absolute;
  display: block;
  background-color: #fff;
  height: 45px;
  width: 45px;
  overflow: hidden;
  z-index: 10;
}
.our-purpose:after,
.our-purpose:before {
  border-left: solid 3px #92d1ff;
}
.our-purpose::before {
  right: -23px;
  top: -23px;
  transform: rotate(-45deg);
}
.our-purpose:before {
  border-left: solid 3px #92d1ff;
}
.para-txt {
  font-size: 40px;
  font-weight: 300;
  margin-top: -60px;
  line-height: 40px;
  color: #7e7e7e !important;
  margin-bottom: 50px !important;
  text-align: center;
  font-family: myriad-pro, Arial;
}

#h2-heading {
  margin-top: -70px;
  font-size: 70px;
  font-weight: 500;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  line-height: 70px;
  color: #000;
}
.row-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#item-7-img {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mb50 {
  margin-bottom: 50px;
}

.aboutus-grid {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.aboutus-grid-title {
  font-size: 24px;
  font-weight: bold;
}

.aboutus-grid-desc {
  font-size: 14px;
  font-family: myriad-pro, Arial !important;
}
@media (max-width: 768px) {
  .title-sml {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .title-sml {
    font-size: 18px;
  }

  .para-ovr {
    font-weight: 400;
  }
}
/* item-8 */
#item-8 {
  height: fit-content !important;
  padding-top: 0 !important;
}
.square-grid {
  margin-top: 20%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  height: 500px;
}

.square {
  background-color: transparent;
  border: 1px solid #888;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  font-weight: 600;
}
.square:hover {
  background-color: #f7f7f7; /* Light white color when hovering */
  color: #000;
}

.column-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 20px;
}
.contact-text {
  font-size: 70px;
  line-height: 80px;
  /* font-family: Times New Roman, Times, serif; */
  font-family: "Oswald", sans-serif;
  color: white;
}
#persistentlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100% !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .nav-link {
    display: none;
  }
  .heading {
    color: #ffffff;
    font-size: 50px;
    line-height: 50px;
  }
  #item-1 {
    /* width: 100vw !important; */
    padding-left: 10px !important;
    padding-top: 100px !important;
  }

  #item-2 {
    width: auto !important;
    /* /* padding-top: 40px;
        padding-left: 0;  */

    padding-left: 0 !important;
  }

  .italic {
    font-size: 20px;
  }
  #item-3 {
    width: 100vw !important;
    height: 100vh !important;
    padding-top: 70px;
  }
  #item-4 {
    width: 100vw !important;
    padding-left: 0px !important;
    padding-top: 60px !important;
  }
  #item-5 {
    margin-top: 0 !important;
    height: auto !important;
    width: 100vw !important;
  }
  #productimg {
    overflow: hidden;
  }

  .col-md-6 {
    width: 100%;
  }
  #item-6 {
    /* margin-top: 280px; */
    padding-top: 0 !important;
    height: auto !important;
    width: auto !important;
    padding-bottom: 0 !important;
  }
  #item-7 {
    margin-top: 200px;
    height: auto !important;
  }
  #item-8 {
    height: auto !important;
    width: 100vw !important;
    /* margin-bottom: 10px; */
  }
  #item-9 {
    height: auto !important;
    width: 100vw !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .col-sm-6 {
    width: 50%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-lg-6 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .home-second-heading {
    font-size: 60px;
    line-height: 60px;
    font-family: "Oswald", sans-serif !important;
    font-weight: 500;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  /* Hide navigation links */
  .nav-link {
    display: none;
  }
  .hidden-xs {
    display: none !important;
  }

  /* Adjust heading styles */
  .heading {
    color: #ffffff;
    font-size: 50px;
    line-height: 50px;
  }

  /* Styles for item-1 */
  #item-1 {
    width: 100vw !important;
    padding-left: 0 !important;
    padding-top: 50px !important;
  }

  /* Styles for item-2 */
  #item-2 {
    width: auto !important;
    height: auto !important;
    padding-left: 0 !important;
  }
  .purpose {
    font-size: 23px;
    line-height: 25px;
    font-weight: 700;
    font-family: myriad-pro, Arial;
    color: #fff;
  }
  #heading {
    padding-left: 0 !important;
    text-align: left !important;

    font-size: 60px;
    line-height: 60px;
    /* font-family: Times New Roman, Times, serif; */
    font-family: "Oswald", sans-serif;
    color: white;

    font-weight: 500;
    padding-top: 0px;
    padding-top: 0px !important;
    margin-bottom: 0 !important;
  }

  /* Adjust italic text */
  .italic {
    font-size: 20px;
  }
  #bg-img {
    max-width: 60% !important;
    max-height: 60% !important;
  }

  /* Styles for
   */
  #item-3 {
    width: 100vw !important;
    height: 100vh !important;
  }

  /* Styles for item-4 */
  #item-4 {
    width: 100vw !important;
    padding-left: 0 !important;
  }

  /* Styles for item-5 */
  #item-5 {
    margin-top: 0 !important;
    height: auto !important;
    width: 100vw !important;
  }

  /* Adjust styles for productimg */
  #productimg {
    overflow: hidden;
  }

  /* Adjust column width for col-md-6 */
  .col-md-6 {
    width: 100%;
  }

  /* Styles for item-6 */
  #item-6 {
    padding-top: 70px;
    height: auto !important;
    width: 100vw !important;
  }

  /* Styles for item-7 */
  #item-7 {
    margin-top: 200px;
    height: auto !important;
  }

  /* Styles for item-8 */
  #item-8 {
    height: auto !important;
    width: 100vw !important;
  }

  /* Styles for item-9 */
  #item-9 {
    height: auto !important;
    width: 100vw !important;
  }
}

img {
  max-width: 100%;
  /* padding-right: 49px; */
}

.col {
  overflow: hidden;
}

figure {
  position: relative;
}

figure figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  transition: 0.3s all ease-in;
  height: 7%;
  background-color: #382261;
  color: #fff;
}
#small-figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 0;
  width: 100%;
  padding: 0;
  transition: 0.3s all ease-in;
  height: 15%;
  /* background-color: rgba(39, 39, 67, 0.7); */
  color: #fff;
}

figure figcaption {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 0;
  transition: 0.3s all ease-in;
  height: 10%;
  background-color: #382261;
  opacity: 0.9;
  color: #fff;
}

#small-figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 0;
  width: 100%;
  padding: 0;
  transition: 0.3s all ease-in;
  height: 15%;
  /* background-color: rgba(39, 39, 67, 0.7); */
  color: #fff;
}

#imgtitle {
  font-size: 20px;
  font-weight: bold;
  left: 0;
  bottom: 20px;
  margin-bottom: -1px;
  padding-left: 10px;
  height: 20%;
}

figure:hover #smallimg-desc {
  margin-bottom: 20px;
}

figure:hover #imgtitle {
  margin-bottom: 150px;
}

figure .title {
  font-size: 24px;
  position: absolute;
  bottom: 0;
  font-weight: bold;
  color: white;
  margin-top: px;
  cursor: pointer;
  bottom: -10px;
}

figure {
  overflow: hidden;
}

figure img {
  transition: 0.3s all ease-in;
}

figure .description {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  transition: 0.3s all ease-in;
  color: #fff;
  font-size: 16px;
  padding: -20px;
  margin-bottom: -40px;
}

#smallimg-desc {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  margin-left: 10px;

  transition: 0.3s all ease-in;
  color: #fff;
  font-size: 16px;
  padding: -20px;
  padding-left: 5px;
  margin-bottom: -120px;
  /* background-color: rgba(39, 39, 67, 0.7); */
}

figure:hover figcaption {
  height: 100%;
}

figure:hover #small-figcaption {
  height: 100%;
}

figure:hover .description {
  margin-bottom: 100px;
}

figure:hover .title {
  margin-bottom: 200px;
}

figure:hover img {
  transform: scale(1.2);
  overflow: hidden;
}

.title {
  padding-left: 10px;
  transition: 0.3s all ease-in;
}

figure .title-small {
  font-size: 20px;
  position: absolute;
  bottom: 0;
  font-weight: bold;
  color: white;
  cursor: pointer;
  bottom: 0;
}
.pt-3 {
  padding-top: 0 !important;
}

/* Mobile view styles */
@media screen and (max-width: 767px) {
  figure .title {
    font-size: 18px;
  }
  .productbtn {
    margin-left: 90px;
    width: 200px;
    margin-top: 10px;
  }
  #productimg1 {
    width: 100%;
  }

  figure .description,
  #smallimg-desc {
    font-size: 14px;
    bottom: -100px;
  }

  figure:hover #imgtitle {
    margin-bottom: 100px;
  }

  figure:hover .title {
    margin-bottom: 150px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  #item-5 {
    padding-bottom: 30px !important;
  }
  #item-6 {
    padding-bottom: 30px !important;
  }
  #small-figcaption {
    overflow: hidden;
  }
  #smallimg-desc {
    overflow: hidden;
    margin-bottom: -220px;
  }
}

/* Tablet view styles */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  figure .title {
    font-size: 20px;
  }

  figure .description,
  #smallimg-desc {
    font-size: 15px;
    bottom: -70px;
  }

  figure:hover #imgtitle {
    margin-bottom: 120px;
  }

  figure:hover .title {
    margin-bottom: 180px;
  }
}

#item-8 {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
}
#ourclient {
  font-size: 70px;
  line-height: 80px;
  /* font-family: Times New Roman, Times, serif; */
  font-family: "Oswald", sans-serif;
  color: black;
  padding-top: 0;
  font-weight: 500;
  margin-top: 20px;
  padding-bottom: 20px;
}

.section-header h2 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  font-weight: 500;
  position: relative;
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}
.our-purpose::before {
  right: 0px;
  top: 0px;
  transform: none;
  display: none;
}
.client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px; /* Set the desired height */
  margin: 20px 0;
}

.client-logo img {
  max-width: 100%;
  max-height: 100%;
}
#ouicksupplierlogo img {
  max-width: 150% !important;
  max-height: 150% !important;
}

.cnt_us_txt {
  padding-top: 100px;
  /* padding-left: 50px; */
  font-size: 65px;
  line-height: 73px;
  font-weight: 900;
  color: #fff;
}

.contactbtn {
  border-radius: 5px;
  background-color: black;
  border: 2px solid rgb(20, 18, 18);
  color: white;

  text-align: center;
  font-size: 25px;
  padding: 5px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0;
  /* letter-spacing: 1px; */
  display: inline;
  /* margin-left: 50px; */
}

.contactbtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.contactbtn span::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.contactbtn:hover {
  color: black;

  background-color: white;
}

.btn-no-effect {
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  display: inline-block;
  padding: 12px 40px;
}

.reques_btn {
  border: 2px solid #000 !important;
  color: #fff !important;
  min-width: 180px;
  padding-bottom: 10px !important;
  padding-left: 50px;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  margin-top: 0px !important;
  background-color: #000 !important;
}

@media (max-width: 1024px) {
  figure .hidden-1024 {
    display: inline;
  }
}

.aboutbtn {
  border-radius: 5px;
  background-color: white;
  border: 2px solid black;
  color: black;
  text-align: center;
  font-size: 25px;
  padding: 5px;
  width: 400px;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0;
}

.aboutbtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.aboutbtn span::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.servicebtn {
  border-radius: 5px;
  background-color: #3e1ef4;
  border: 2px solid black;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  padding: 5px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
}

.servicebtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.servicebtn span::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.servicebtn:hover span {
  padding-right: 25px;
}

.servicebtn:hover span:after {
  opacity: 1;
  right: 0;
}

.pq {
  font-size: 65px;
  font-weight: 500;
  font-family: Times New Roman, Times, serif;
  line-height: 70px;
  color: #000000;
  padding-top: 0;

  /* padding-top: px; */
}

.btn {
  color: black !important;
  border: 1px solid #fff;
  position: relative;
  z-index: 1;
  display: inline-block;
  background-color: white !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 12px 50px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  letter-spacing: 1.5px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
}
.btn:hover {
  background-color: black !important;
  color: white !important;
}

.btn span {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.text-uppercase {
  text-transform: uppercase;
}
.pt75 {
  padding-top: 75px;
}
@media only screen and (max-width: 767px) {
  #Image {
    margin-right: 10px;
    padding-right: 68px;
    padding-left: 75px;
  }
}
#Image {
  max-width: 100%;
  padding-right: 49px;
}
.honeycomb-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%; /* Adjust the container size as needed */
  margin: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
}

.honeycomb-row {
  display: flex;
  justify-content: center; /* Center the rows horizontally */
  flex-wrap: wrap; /* Allow the hexagons to wrap on smaller screens */
}

.hexagon {
  width: 175px;
  height: 150px; /* Calculate the height using the formula: width * sqrt(3) / 2 */
  /* background-color: #DF5A14;  */
  background-color: #e9e0cf;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  margin-top: -15px;
  margin-bottom: -15px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  /* border: 4px solid #f4b051;  */
  box-sizing: border-box; /* Ensure border is included in the box size */
  /* box-shadow: inset 0 0 10px rgba(0.5, 1.5, 1, 1);  */
  transition: all 0.3s ease-in-out; /* Add transition properties */
}
.hexagon-link {
  text-decoration: none;
}
.hex-text {
  color: #000;
  font-family: system-ui;
}

.hexagon:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
  background-color: #f0ae8b;
  color: white;
  cursor: pointer;

  text-decoration: none;
}

.even-row {
  margin-left: 0px !important;
}
.odd-row {
  /* margin-left: -200px; */
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .hexagon {
    width: 100px; /* Adjust the hexagon size for small screens */
    height: 80px; /* Adjust the height proportionally */
    margin: 3.5px;
    margin-bottom: -10px;
    margin-left: 10px;
  }
  .hex-text {
    font-size: 12px !important;
  }
  #bg-img {
    max-width: 36% !important;
    max-height: 60% !important;
  }
}
.container {
  padding: 0 !important;
  padding-left: 0px !important;
}

/* Media query for small screens */

/* Default styles for all screen sizes */
.screen {
  height: 100vh !important;
}

.nav-link span {
  color: #fff;
  font-weight: bolder;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  padding-top: 5px;
  font-size: 14px;
}

/* Other styles... */

/* Mobile Styles */
@media screen and (max-width: 767px) {
  /* Common styles for portrait and landscape orientations */
  .nav-link {
    display: none;
  }

  /* Portrait orientation */
  @media (orientation: portrait) {
    /* Add styles specific to portrait orientation */
    .heading {
      font-size: 30px;
      line-height: 30px;
    }

    /* Add other portrait-specific styles */
  }

  /* Landscape orientation */
  @media (orientation: landscape) {
    /* Add styles specific to landscape orientation */
    .heading {
      font-size: 50px;
      line-height: 50px;
    }

    /* Add other landscape-specific styles */
  }

  /* Common styles for both portrait and landscape orientations */
  .italic {
    font-size: 20px;
  }

  /* Add other common styles for mobile screens */
  #bg-img {
    max-width: 60% !important;
    max-height: 60% !important;
  }
}

/* Tablet Styles */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Add styles for tablet screens */
  .nav-link {
    display: none;
  }

  .heading {
    font-size: 40px;
    line-height: 40px;
  }

  .italic {
    font-size: 20px;
  }
  p {
    font-size: 23px;
  }
  #item-1 {
    padding-top: 7% !important;
    padding-left: 7% !important;
    color: #ffffff;
  }
  #item-2 {
    padding-top: 0 !important;
    color: #ffffff;
    height: auto !important;
    padding-bottom: 5%;
    overflow: hidden;
  }
  #heading {
    line-height: 60px !important;
  }
  .purpose {
    font-size: 22px;
    line-height: 25px;
  }
  #item-3 {
    height: fit-content !important;
    /* padding-top: 0% !important; */
  }
  #item-4 {
    height: fit-content !important;
    padding-top: 0 !important;
  }
  #item-5 {
    height: auto !important;
    color: #ffffff;
    overflow: hidden !important;
  }
  #item-6 {
    /* padding-top: 37%; */
    padding-bottom: 8%;
    color: #ffffff;
    height: auto !important;
    overflow: hidden !important;
  }
  #item-7 {
    color: #ffffff;
    height: auto !important;
    overflow: hidden !important;
  }
  #item-8 {
    padding-bottom: 1% !important;
    color: #ffffff;
    height: auto !important;
    overflow: hidden;
  }
  #item-9 {
    color: #ffffff;
    height: auto !important;
    overflow: hidden;
  }
  .col-md-9 {
    margin-top: 0 !important;
  }

  /* Add other tablet-specific styles */
}

.fullCoverImg {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-size: cover; */
  /* margin-top: 20px; */
  /* margin-bottom: 40px; */
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media only screen and (max-width: 545px) {
  .fullCoverImg {
    height: 1000px;
    width: 120%; /* Increase the width by 20% */
    margin-left: -10%; /* Adjust the margin to center the image */
    margin-right: -10%;
    overflow-x: hidden;
    background-size: 150%;
  }
  #whyimg {
    height: 1000px;
    width: 120%; /* Increase the width by 20% */
    margin-left: -10%; /* Adjust the margin to center the image */
    margin-right: -10%;
    overflow-x: hidden;
    background-size: 100%;
  }
}

#why {
  margin-left: -95px;
}

#what {
  margin-top: -90px;
}
#How {
  margin-left: -86px;
}
#item-9 {
  height: fit-content !important;
}

@media only screen and (max-width: 914px) {
  #why {
    margin-left: 0;
    text-align: left;
  }
  .agile-digital-bg-img {
    margin-left: -10%; /* Adjust the margin to center the image */
    margin-right: -10%;
    overflow-x: hidden;
    background-size: 130%;
    margin-bottom: -10%;
  }
  #How {
    margin-left: 10px;
    text-align: left;
    margin-top: -50px;
  }
  #what {
    text-align: left;
  }
}

@media only screen and (max-width: 820px) {
  .agile-digital-bg-img {
    margin-top: -200px;
  }

  #How {
    margin-left: 0px;
  }

  #The-pharmacist-store-heading {
    font-size: 40px;
  }
}

@media only screen and (max-width: 545px) {
  .agile-digital-bg-img {
    height: 1000px;
  }
  #productimg1 {
    margin-left: 16px;
  }
  .main-div {
    overflow-x: hidden;
  }
  #okedit101 {
    margin-left: 7px;
  }

  #bharatmake-initiative {
    margin-left: 10px;
    margin-right: 10px;
  }
  #okedit102 {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 420px) {
}
