.footercontainer {
  padding-left: 164px !important;
  padding-right: 164px !important;
}
#contact {
  color: black;
  font-size: 16px;
  text-decoration: none;
}
h6 {
  text-transform: uppercase;
}
#text {
  font-size: medium;
  text-decoration: none;
  color: black;
}
#footer-title {
  font-size: 24px;
}
.text-uppercase {
  color: black;
}
p {
  color: black;
}
#footer-line {
  font-family: myriad-pro, Arial;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  /* background-color: gray; */
  color: black;
}
#myImage {
  margin-right: 10px;
  padding-right: 40px;
}

@media screen and (max-width: 600px) {
  .col-md-2 {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .footercontainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #myImage {
    margin-right: 10px;
    padding-right: 40px;
  }
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black; /* Add black background */
  padding: 10px 20px;
}

#footer-line {
  color: white;
  text-decoration: none;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icon {
  color: white;
  text-decoration: none;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footercontainer {
    padding-left: 11% !important;
    padding-right: 5% !important;
  }
  #footer-title {
    font-size: 18px;
  }
}

.container {
  margin: 0 auto;
  max-width: 1500px;
}

@media (min-width: 768px) {
  .col-md-4 {
    /* flex: 0 0 auto; */
    width: 33.33333333%;
  }
}

.container {
  margin: 0 auto;
  max-width: 1500px;
}
