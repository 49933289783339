.kyc-heading{
    background-color: purple;
    font-size: 32px;
    width: 150%;
    height: 220px;
    padding-left: 170px;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
   
}


.kyc-para{
    padding-left: 25px;
    text-align: center;
    font-size: 21px;
}
.imagkyc{
    margin-left: 15%;
    margin-bottom: 30px;
}

/* CSS for the feature table container */
.feature-table {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
 
  gap: 20px; /* Adjust the gap between cards as needed */
  /* margin-top: 20px; */
}
  
  /* CSS for each feature box */
  .feature-box {
    width: 48%; /* Adjust the width for a 2-column layout */
    text-align: center;
    margin-bottom: 20px;
  }
  
 /* CSS for the inner box */
.box {
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s; /* Add a transition for the zoom effect */
    font-size: 1.2rem; /* Increase the font size of text */
  }
  
  /* CSS for the icons */
  .box i {
    font-size: 2rem; /* Increase the font size of icons */
  }
  
  /* Hover effect: zoom in on hover */
  .box:hover {
    transform: scale(1.1); /* Zoom in */
  }
  
  
  
  /* Media query for smaller screens to create a 1-column layout */
  @media (max-width: 767px) {
    .box {
      width: 100%;
      margin-bottom: 20px;
      margin-right: -10px;
    }

    .kyc-heading{
      padding-left: 5px;
    }

    .kyc-para{
      text-align: left;
      padding-left: 5px;
    }
    .imagkyc{
 
  width: 100%;
    }
    .k{
      overflow-x: hidden;
    }
  }
  
  
  