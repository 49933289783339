.createsavingacc{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 170px;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
}
@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  