.KPO{
    background-image: url('https://www.gadgetsloud.com/wp-content/uploads/2022/05/online-g49b5c1a5d_1280.png');
    background-size: 100% 100%; /* Fit the background to the container's width and height */
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 32px;
    width: 100%; /* You can adjust the width as needed */
    height: 220px;
    padding-left: 6.8%;
    padding-top: 100px;
    color: white;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}
.servicesw-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-left: 7%;
    margin-right: 20px;
}

.servicew {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    background-color: #f5f5f5;
    transition: transform 0.2s, background-color 0.2s; /* Add transition for zoom and background color change */
}

.servicew:hover {
    transform: scale(1.05); /* Zoom in effect on hover */
    background-color: #3498db; /* Change background color on hover */
}

h3 {
    font-size: 24px;
    
}

p {
    font-size: 16px;
}

ul {
    list-style: none;
    padding-left: 0;
}

li {
    margin: 8px 0;
}
/* Media query for screens smaller than 768px wide (typical mobile screens) */
@media (max-width: 768px) {
   
    
    .servicew {
        padding: 5px;
        font-size: 18px;
        background-color: #ccc;
    }

    .servicesw-grid{
        grid-template-columns: 1fr;
        
    }
    
    h3 {
        font-size: 18px;
    }
}