strong {
    font-weight: 600 !important;
}
p{
    font-weight: 500 !important;
    margin: 0 0 10px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
   
    font-size: 18px;
    font-weight: 300;
    color: #000000;
    
    
}
.h3{
    font-weight: 900;
    margin: 10px 0;
}
#termsedit1{
  margin-left: -40px;
  font-family: 'Oswald', sans-serif;
}
li{
    font-weight: 500 !important;
    margin: 0 0 10px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: myriad-pro, Arial;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
}
@media only screen and (max-width: 768px) {
    .pt75 {
      text-align: left;
      font-size: 16px;
      line-height: 1.5;
      padding-left: 20px;
      
    }
  
    p, li {
      /* text-align: left; */
      font-size: 16px;
  }

  h3 {
    /* text-align: left; */
      font-size: 20px;
  }
  }