/* .container-fluid {
  padding: 0 !important;
  margin: 0 !important;
  z-index: 999;
  color: white !important;
  position: fixed;
  background-color: rgba(255, 255, 255, 0);

  
} */
.container-fluid {
    background: linear-gradient(180deg,#000c,#fff0);
    color: #fff!important;
    margin: 0!important;
    padding: 0!important;
    position: fixed;
    transition: background .5s;
    z-index: 999;
}

#navbar-container {
  flex-wrap: nowrap;
}

.nav {
  color: white !important;
}

.navbar {
  margin: 0px !important;
  padding: 0px !important;
}

.navbar-brand {
  padding: 0px;
  margin: 0px !important;
  margin-left: 100px;
  z-index: 1000;
  color: white;
}

li {
  margin-right: 20px;
}

.menu-link {
  color: #3f3f3f;
  font-weight: 800;
  font-size: 23px;
  text-decoration: none;
  font-family: myriad-pro, Arial, sans-serif;
  border-bottom: #3f3f3f;
}

.mt5 {
  font-size: 14px;
}

.nav-item .menu-link {
  color: white;
  font-size: 16px;
  margin: 0 10x;
  font-weight: 600;
  font-family: system-ui;
}

.nav-data {
  right: 0;
  margin: auto;
  margin-right: 100px;
}

.menu-link:hover {
  text-decoration: underline;
}

.menu-link .active {
  color: rgb(38, 104, 70) !important;
}

#myImage {
  margin-left: 50%;
}

.toogle-btn {
  margin: auto;
  right: 0;
  width: 50px;
  height: 50px;
}

.toogle-btn {
  z-index: 1001;
  margin-right: 30px;
  padding: 0px;
  border: none;
  border-radius: 50%;
  background-color: #ffffff;
}

.toogle-btn-bg {
  background-color: black;
  opacity: 0.8;
  transition: ease-in 0.1s all;
}

.hamburger {
  right: 0;
  margin-right: 28px;
  margin-top: -6px;
  z-index: 1000;
  height: 51px;
  width: 51px;
  cursor: pointer;
  /* position: absolute; */
  overflow: hidden;
}

.ver-line {
  height: 3px;

  background-color: black;
  position: relative;
  top: 11px;
  margin: 8px auto 0 auto;
}

.var-line-scroll {
  background-color: white;
}

.bottom {
  transform: rotate(0deg) translateY(0px);
  transition: 0.5s;
  width: 22px;
  height: 2px;
}

.open .bottom {
  transform: translateY(-14px) rotate(-45deg);
  transition: 0.5s;
}

.top {
  transform: rotate(0deg) translateY(0px);
  transition: 0.5s;
  width: 22px;
  height: 2px;
}

.open .top {
  transform: translateY(7px) rotate(45deg);
  transition: 0.5s;
}

.middle {
  opacity: 1;
  transition: 0.6s;
  width: 10px;
  height: 2px;
  margin-right: 15px;
}

.open .middle {
  opacity: 0;
  transition: 0.6s;
}

.border {
  margin: auto;
  background-color: #468;
}

.open .draw_top {
  animation: rightIn 0.4s 0s forwards;
}

.draw_top.unactive {
  animation: leftIn 0.4s 0.3s forwards;
}

.open .draw_left {
  animation: topIn 0.4s 0.3s forwards;
}

.draw_left.unactive {
  animation: bottomIn 0.4s 0s forwards;
}

.open .draw_bottom {
  animation: leftIn 0.4s 0s forwards;
}

.draw_bottom.unactive {
  animation: rightIn 0.4s 0.3s forwards;
}

.open .draw_right {
  animation: bottomIn 0.4s 0.3s forwards;
}

.draw_right.unactive {
  animation: topIn 0.4s 0s forwards;
}

.draw_top {
  position: absolute;
  top: 0;
  left: 51px;
  transition: 1s;
}

.draw_left {
  position: absolute;
  top: -50px;
  left: 0;
  transition: 1s;
}

.draw_bottom {
  position: absolute;
  top: 49px;
  left: -50px;
  transition: 1s;
}

.draw_right {
  position: absolute;
  /* top: 51px; */
  left: 49px;
  transition: 1s;
}

@keyframes rightIn {
  0% {
    left: 50px;
  }

  100% {
    left: 0px;
  }
}

@keyframes topIn {
  0% {
    top: -50px;
  }

  100% {
    top: 0px;
  }
}

@keyframes leftIn {
  0% {
    left: -50px;
  }

  100% {
    left: 0px;
  }
}

@keyframes bottomIn {
  0% {
    top: 50px;
  }

  100% {
    top: 0px;
  }
}

.sidebar {
  z-index: 1000;
  top: 0;
  position: absolute;
  right: 0;
  width: 300px;
  padding: 35px;
  background-color: white;
  height: 100vh;
  overflow-y: scroll !important;
  background-image: url("/public/Images/sidebar-background.png");
}

.close {
  display: none;
}

/* For screens with a maximum width of 1024px (iPad view) */
@media (max-width: 1024px) {
  .sidebar {
    width: 100%;
    /* Make the sidebar occupy the full width */
    right: auto;
    /* Reset the right positioning */
    left: 0;
    /* Set the left positioning to 0 */
  }

  .close {
    display: none;
    /* Display the close button for iPad view */
  }
}

.mt-4 .menu-link {
  display: none;
}

.social-container {
  background: #eee;
}

a.social {
  margin: 0 0.5rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}
a.instagram {
  color: #e1306c;
}

a.Linkdin {
  color: black;
}

.nav-background {
  background-color: transparent;
  background-color: transparent;
  transition: ease-in 0.1s all;
}

@media only screen and (max-width: 1000px) {
  .nav-data {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar {
    z-index: 1000;
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 35px;
    background-color: white;
    overflow-y: scroll !important;
    background-image: url("/public/Images/sidebar-background.png");
    transition: transform 0.3s ease;
    display: block;
  }

  .close {
    display: none;
  }

  .mt5 {
    font-size: 14px;
  }

  .nav-data {
    display: none !important;
  }

  .mt-4 .menu-link {
    font-size: small;
    display: block !important;
  }

  .navbar-brand {
    margin-top: 70px;
    margin-left: 0px;
  }

  .hamburger {
    margin-top: -10px;

    /* margin-right:  	-10px !important; */
  }

  #myImage {
    margin-left: 15px;
  }

  .sidebar {
    overflow-y: scroll !important;
  }

  .nav-data {
    display: none !important;
  }

  .mt-4 .menu-link {
    font-size: small;

    display: block !important;
  }

  .navbar-brand {
    margin-top: 70px;

    margin-left: 0px;
  }

  .container-fluid.nav-background {
    background-color: transparent;

    color: black !important;
    /* Set text color to black for better visibility */
  }

  .hamburger {
    /* Update hamburger icon styles for better visibility on white background */

    background-color: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #myImage {
    margin-left: 10%;
  }
}

.social-media-bar {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.social-media-bar a {
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}

a.youtube {
  color: #eb3223;
}

a.instagram {
  color: #e1306c;
}

a.twitter {
  color: #49a1eb;
}

a.facebook {
  color: #4968ad;
}

a.social {
  display: inline-block;
  margin: 0 0.5rem;
  transition: transform 0.25s;
}
