.containerl {}

.columnq {
  width: 100%;
  height: 150px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;

}

.columnq .icon {
  padding-top: 10px;
  font-size: 24px;
  margin-bottom: 10px;
}

.columnq h3 a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.columnq h3 a:hover {
  color: #ff7700;
}