/* Adjustments for mobile view */
@media (max-width: 768px) {
  /* Contact container adjustments */
  .contact-container {
    padding: 10px;
  }

  .contact-container h1 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Grid container adjustments */
  .grid-container {
    grid-template-columns: 1fr; /* Single column layout for mobile */
    gap: 10px; /* Reduce the gap between grid items */
  }

  .grid-item {
    /* Adjust grid item styles for mobile view */
    padding: 10px;
    text-align: center;
  }
  .grid-item progress {
    display: none;
  }
  /* Form container adjustments */
  .form-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  /* Location section adjustments */
  .Locations {
    height: auto; /* Auto height for mobile view */
  }

  .Location-name {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .address-text {
    font-size: 16px;
    padding-left: 10px;
  }

  /* Contact section adjustments */
  .contact-section {
    display: flex;
    flex-direction: column; /* Stack contact info vertically */
  }

  .contact-column {
    margin-bottom: 20px;
  }

  .contact-info p {
    font-size: 14px;
  }
}
