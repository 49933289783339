.section5jsxcard {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  height: 70%;
  margin-left: -3%;
}
.section5jsxcard-3 {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  height: 70%;
  margin-left: -3%;
 
  margin-bottom: 30px;
}
 
.section5jsxcard-2 {
  width: 75%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  height: 60%;
  margin-left: -3%;
 
  margin-bottom: 30px;
}
.card {
  width: 100%;
  display: flex;
  margin: 1rem;
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(164, 157, 157, 0.05),
    0 1px 2px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  background: #fff;
  /* height: 100%; */
  /* flex-wrap: wrap; */
 
  /* position: relative; */
  /* overflow: hidden; */
}
 
.card:hover,
.card.hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3), 0 0 1px 0 rgba(0, 0, 0, 0.25);
}
 
.card:hover .card-content,
.card.hover .card-content {
  box-shadow: inset 0 3px 0 0 #ccb65e;
  border-color: #ccb65e;
}
 
.card:hover .card-img .overlay,
.card.hover .card-img .overlay {
  background-color: rgba(25, 29, 38, 0.85);
  transition: opacity 0.2s ease;
  opacity: 1;
}
 
.card-img {
  position: relative;
  height: 224px;
  width: 100%;
  background-color: #fff;
  transition: opacity 0.2s ease;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
 
.card-img .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
}
 
.card-img .overlay .overlay-content {
  line-height: 224px;
  width: 100%;
  text-align: center;
  color: #fff;
}
 
.card-img .overlay .overlay-content a {
  color: #fff;
  padding: 0 2rem;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  height: auto;
  line-height: 40px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
}
 
.card-img .overlay .overlay-content a:hover,
.card-img .overlay .overlay-content a.hover {
  background: #ccb65e;
  border-color: #ccb65e;
}
 
.card-content {
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-top: 1px solid #e9e9eb;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 1rem ;
  /* padding: 1rem; */
  transition: all 0.2s ease;
}
 
.card-content a {
  text-decoration: none;
  color: #202927;
}
 
.card-content h2,
 
/* .card-content h2,
.card-content a h2 {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: system-ui; */
  /* height: auto;
    width: 100%; */
 
.casestudyjsx-h2{
  font-size: 20px;
  font-weight: 500;
  font-family: system-ui;
}
 
.p {
  /* font-size: .8rem; */
  font-weight: 400;
  /* white-space: nowrap; */
  overflow: hidden;
  width: 100%;
  height: 70%;
  /* display: flex;
  flex-wrap: wrap; */
 
  /* text-overflow: ellipsis; */
  color: rgba(32, 41, 28, 0.8);
}
 
 
.casestudyjsx-p{
  font-size: 16px;
  font-weight: 100;
  padding-top: 20px;
  font-family: system-ui;
}
@media only screen and (max-width: 550px) {
  .section5 {
    height: auto;
  }
  .section5jsxcard {
    grid-template-columns: 1fr;
    height: auto;
    width: 85%;
    margin-left: -5%;
  }
  .section5jsxcard-3 {
    grid-template-columns: 1fr;
    height: auto;
    width: 85%;
    margin-left: -5%;
  }
  .section5jsxcard-2 {
    grid-template-columns: 1fr;
    height: auto;
    width: 85%;
    margin-left: -5%;
  }
}
 
@media only screen and (min-width: 551px) and (max-width: 1000px) {
  .section5 {
    height: auto;
  }
  .section5jsxcard {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    width: 85%;
    margin-left: -5%;
    margin-bottom: 30px;
  }
  .section5jsxcard-3 {
    grid-template-columns: 1fr;
    height: auto;
    width: 85%;
    margin-left: -5%;
    margin-bottom: 30px;
  }
  .section5jsxcard-2 {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    width: 85%;
    margin-bottom: 30px;
    margin-left: -5%;
  }
}
 
