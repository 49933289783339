.cards-heading{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 170px;
    padding-top: 100px;
    color: white;
    font-family: myriad-pro, Arial;
}
.cards-para{
    padding-left: 170px;
    font-size: 21px;
}