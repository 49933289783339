/* SaasDevelopment.css */
.saas-heading{
    background-color: purple;
    font-size: 32px;
    width: 100%;
    height: 220px;
    padding-left: 6.7%;
    padding-top: 100px;
    color: white;
    font-family: 'Oswald', sans-serif;
}

  
  /* Default styles for larger screens */
.sectionss-heading {
  padding-top: 20px;
  padding-left: 30px; 
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  
}

/* Mobile view styles */
@media (max-width: 768px) {
  .section-heading {
    font-size: 20px; /* Adjust the font size for mobile view */
    padding-top: 20px; /* Reduce the top padding for better spacing */
  }
}

  
  .section-description {
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 30px;
  }
  .subpoint{
    text-align: center;
    font-weight: bold;
    font-family:myriad-pro, Arial;
    font-size: 25px;
    
  }
  /* Add styles for the rest of your content */
  /* SaasProducts.css */
/* SaasProducts.css */
.saas-products-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
   
  }
  
 /* SaasProducts.css */
 .saas-card {
    flex-basis: calc(50% - 20px);
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
 }

  .e-commerce-card {
   
   
    color:black;
   background-color:rgb(218, 218, 218)
  }
  .shopify-card{
   
    color:black;
   background-color:rgb(218, 218, 218)
  }
  .sap-card{
    
   
    color:black;
   background-color:rgb(218, 218, 218)

  }
  .cloud-card{
    
    color:black;
   background-color:rgb(218, 218, 218)

  }
  .googole-card{
    
   
    color:black;
   background-color:rgb(218, 218, 218)

  }
  .paltform-card{
    
    
    color:black;
   background-color:rgb(218, 218, 218)

  }
  .custom-card{
  
    
    color:black;
   background-color:rgb(218, 218, 218)

   
  }
  .networking-card{
  
    color:black;
   background-color:rgb(218, 218, 218)

  }

  .saas-card h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .saas-card p {
    color:black;
    font-size: 16px;
    margin: 0;
    /* color: white; */
  }
  /* WhyChooseUs.css */

.why-choose-us {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 20px 0;
    
  }
  
  .why-choose-us h2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
    padding-left: 30px;
    font-family: 'tungstenw05-medium', 'Oswald', sans-serif;
  }
  
  .why-choose-us ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .why-choose-us li {
    margin-bottom: 10px;
  }
  
  .why-choose-us li strong {
    font-weight: bold;
  }
  /* IndustriesWeServe.css */

/* Your CSS file */


.r1{
    padding-top: 30px;
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
   text-align: center;
  
  }

  
  .simple-list li {
    display: inline-block; /* Display list items as inline-block elements */
    margin-right: 20px; /* Adjust spacing between list items */
  }
  
  .simple-list li:last-child {
    margin-right: 0; /* Remove margin-right from the last list item */
  }
  
  
  
  .digital-transformation  {
    font-size: 18px; /* Font size for the text */
    color: #333; /* Text color */
    line-height: 1.5; /* Line height for better readability */
    padding-top: 50px;
    padding-left: 30px;
  }
  
  
  
  
  /* Style as needed */
  
  
  /* Customize these styles to match your design preferences */
  
  /* For screens with a maximum width of 767px (typical mobile view) */
@media (max-width: 767px) {

  .section-heading {
    font-size: 20px; /* Adjust the font size as needed */
  }

  .saas-products-container {
    grid-template-columns: 1fr; /* Set a single column layout for mobile */
  }

  .saas-card {
    flex-basis: calc(100% - 20px); /* Make each card occupy the full width */
  }
  .saas-heading{
    padding-left: 25px;
  }
}
