/* Main container styles */
.cont {
  overflow: hidden;
}
.contact-container {
  background-image: url("https://akkim.com.tr/wp-content/uploads/2022/07/iletisim.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: grid; /* Use flex display to align content */
  align-items: center; /* Vertically center the content */
  padding-left: 20px;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  padding-top: 10px;
  color: #fff;
  padding-left: 13%;
  font-size: 18px;
}

.left-heading {
  margin: 0; /* Remove default margin for the heading */
}
.contact-container h1 {
  margin: 0;
  padding-left: 30px;
  font-size: 42px;
  margin-top: 50px;
  margin-bottom: 50px;
  /* Adjust the padding for better mobile view */
}

.additional-content {
  padding: 20px;
  background-color: white;
}

.looking-for {
  font-size: 42px;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 75px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
  font-family: tungstenw05-medium, Oswald, sans-serif;
}
/* Grid styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-item {
  background-color: #f2f2f2; /* Set the background color to white */
  padding: 20px;
  text-align: center;
}

.grid-item h2 {
  font-size: 20px;
  font-weight: bold;
  font-family: myriad-pro, Arial;
  margin: 0;
  padding: 5px 0;
  color: black !important; /* Set the default color to black */
}

.grid-item:hover h2 {
  color: rgb(
    65,
    63,
    63
  ) !important; /* Set the color to black on hover as well */
}

.grid-item a {
  text-decoration: none; /* Remove the underline from anchor tags */
  color: black; /* Set the color to black to match the heading color */
}

.Contact_page {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: left;
  padding-top: 10px;
}

div.Contact_page h1 {
  color: black;
  font-size: 42px;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  text-align: center;
  margin-left: 10px; /* Add a left margin to move the heading to the left */
  padding-top: 30px;
  margin-top: 10px;
  padding-bottom: 30px;
  background-image: url("https://img.freepik.com/premium-photo/banner-flowing-waves-bright-blue-background-copy-space_106885-3572.jpg?w=2000");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Contact_page p {
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center; /* Add text alignment to the left */
  margin-left: 20px; /* Add a left margin for slight adjustment */
}

.form-container {
  display: grid;
  padding-left: 22px;
  grid-template-columns: 1fr 1fr; /* Divide the container into two equal columns */
  gap: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
  font-family: "Arial", sans-serif;
}

/* Contact Form Inputs */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Contact Form Submit Button */
.submit-button {
  background-color: #09b007;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px; /* Add margin to move the button down */
  padding-left: 15px; /* Adjust the left padding to move the button to the left */
}

.submit-button:hover {
  background-color: #02910e;
}

/*Location */

.Locations {
  position: relative;
  margin-top: 30px;
  background: #dedede;
  background-repeat: repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  background-position: top center;
  height: 400px;
}
.Location-name {
  font-size: 42px;
  color: black;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  text-align: center;
  margin-left: 10px;
  padding-top: 30px;
  margin-top: 10px;
  padding-bottom: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.address-text {
  font-size: 20px;
  color: black;
  padding-left: 30px;
  margin-bottom: 20px;
}

/* .maps {
  border: 0;
  margin-right: 20%;
  margin-left: 70%;
  margin-top: 50px;
  margin-top: -30%;
  width: 350px;
  height: 230px;
} */

.h30 {
  color: #000;
  padding-bottom: 20px;
  padding-left: 20px;
  font-size: 28px;
}
.h31 {
  color: #000;
  font-size: 28px;
  margin: 10px, 0;
  padding-bottom: 20px;
  padding-left: 20px;
}
.h32 {
  margin: 10px, 0;
  font-size: 28px;
  color: #000;
  padding-bottom: 20px;
  padding-left: 20px;
}
.contact-section {
  display: flex;
  gap: 5px;
  padding-top: 20px;
  padding-left: 15px;
}

.contact-column {
  flex: 1;
}

.contact-info {
  display: flex;
  align-items: flex-start;
}

.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.contact-icon svg {
  width: 100%;
  height: 100%;
  fill: #000; /* Change the fill color as needed */
}

.contact-info p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.container-yq {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 2%;
  background-color: #f1f1f1;
  overflow-x: hidden;
}

.conatctheadingat {
  font-size: 50px;
  transform: scaleX(-1);
  margin-top: 22%;
  text-align: center;
  color: white;
  padding-left: 40%;
  font-family: "Oswald", sans-serif;
}

.form-groupcaptcha {
  margin-left: 100px;
  margin-top: 50px;
}

.contactheading {
  font-size: 3.6rem;
  line-height: 5rem;
  padding: 0px;
  /* padding-top: 7%;
  padding-left: 7%; */
  font-family: "Oswald", sans-serif;
  color: #fff;
}

.contactp {
  display: flex;
  flex-wrap: wrap;
  /* padding-left: 7%; */
  padding-top: 1%;
  color: #fff;
  font-size: 16px;
}

.rows {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 1fr;
  width: 100%;
  margin-top: 2%;
  padding: 2% 7%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
}
.rows1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* margin-top: 2%;
  padding: 2% 7%; */
}

.columnb {
  background-color: #f1f1f1;
  width: 75%;
}
.columnb.hover {
  background-color: rgb(248, 248, 247);
  width: 75%;
}

.columnb textarea {
  background-color: #fff;
  width: 120%; /* Make the textarea full width */
  height: 270px; /* Set an initial height */
  resize: vertical; /* Allow vertical resizing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 20px; /* Add some bottom margin */
  justify-content: center;
  align-items: center;
}
.contactusresponsive {
  overflow-x: hidden;
}
/* Media query for mobile view */
@media only screen and (max-width: 768px) {
  .columnb textarea {
    height: 200px; /* Increase height for better usability on smaller screens */
  }
  .rows {
    grid-template-columns: 1fr;
  }
  .rows1 {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

/* Media query for smaller mobile devices */

/* Full-width input fields */
.input,
.wpcf7-textarea {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  outline: none;
  border: none;
  color: black;
  border-bottom: 2px solid rgb(48, 47, 47);
  box-sizing: border-box;
  background-color: #f1f1f1;
}
.wpcf7-textarea {
  /* border: 2px solid rgb(174, 174, 173); */
  border: 2px solid orange;
}
.wpcf7-textarea::placeholder {
  color: #7e7e7e;
}

.grid-container-a {
  width: 500px;
  /* margin-left: 10%; */
  margin-left: 100px;

  /* gap: 40px; Gap between columns */

  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: flex-start; /* Align items to the start of the grid */
  align-items: center; /* Center items vertically */
}
.link-form-contact {
  height: 60px;
  width: 30%;
  /* margin-left: 10%; */
}

.usemailagree {
  font-family: system-ui;
  color: #000;
  padding-left: 7%;
}
.linkdln {
  justify-self: flex-start; /* Align the LinkedIn button to the end of its column */
}

/* Adjustments for mobile view */
@media only screen and (max-width: 600px) {
  .grid-container-a {
    grid-template-columns: 1fr; /* Change to a single column for smaller screens */
    justify-content: center; /* Center items horizontally */
  }

  .linkdln {
    justify-self: center; /* Center the LinkedIn button in the single column */
  }

  .registerbtn {
    margin-left: 0; /* Remove negative margin for mobile */
  }
  .columnb textarea {
    margin-top: 30px;
    width: auto;
    height: 200px; /* Increase height for better usability on smaller screens */
  }
}

/* Set a style for the submit button */
.registerbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 30%;
  opacity: 0.9;

  /* margin-left: -65%; */
}

.agree-policy {
  margin-bottom: 15px;
  margin-top: -1%;
  color: rgb(0, 0, 0);
  padding-left: 3%;
}

.agree-policy label {
  font-family: system-ui;
  display: inline;
}

.agree-policy input[type="checkbox"] {
  margin-left: 50px;
  height: max-content;
}

@media only screen and (max-width: 650px) {
  .agree-policy input[type="checkbox"] {
    margin-left: 0px;
    height: max-content;
  }
}

.robot-captcha {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  width: 23%;
  margin-bottom: 20px;
  height: auto;
  margin-left: 6%;
  margin-top: 5%;
  display: flex;
  align-items: center; /* Vertically align content */
}

.robot-captcha label {
  font-weight: bold;
  text-align: center;
}

.robot-captcha input[type="checkbox"] {
  margin-right: 10px;
  height: 50px;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}

@media (max-width: 768px) {
  .contactheading {
    font-size: 30px;
    line-height: 30px;
    padding-top: 5%;
  }

  .contactp {
    font-size: 14px;
  }

  .registerbtn,
  a {
    margin-bottom: 15px;
  }

  .robot-captcha {
    margin-top: 10%;
  }
  /* .registerbtn {
    width: 40%;
  } */
  .robot-captcha {
    width: 40%;
  }
}
@media (max-width: 845px) {
  .grid-container-a {
    gap: 70px;
  }

  .registerbtn {
    width: 28%;
  }
  .address1 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 720px) {
  .grid-container-a {
    gap: 80px;
  }
  .registerbtn {
    width: 35%;
  }
}

.contactmaindiv {
  height: auto;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: center;
  overflow-y: scroll;
  opacity: 1; /* Initially set opacity to 0 */
  transition: opacity 0.2s ease-in;
}

.visible {
  opacity: 1; /* Set opacity to 1 when visible */
}

.addressApp {
  overflow-x: hidden;
  width: auto;
  height: auto;
  margin-top: 100px;
  font-family: "Oswald", sans-serif;
}

.address1 {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: auto;
  gap: 1rem;
  /* border-top: 1px solid; */
  justify-content: center;
  align-items: center;
  position: relative; /* Added */

  opacity: 1;
}
.horizontal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 10%;
  /* direction: ltr; */
}
.address1:hover {
  opacity: 1;
}

/* Added */
.address1::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: 0px;
  width: 20px;
  background: rgb(224, 120, 9);
  z-index: 1;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.address1:hover::before {
  opacity: 1;
  transform: translateY(0);
}

/* Added */
.address1:hover {
  overflow-x: hidden;
}

.add-city,
.add-landmark iframe {
  /* height: 100%; */
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: start;
  margin-top: 5%;
}

.add-landmark {
  justify-content: left;
}

li {
  list-style-type: none;
}

/* .add-city,
.add-landmark:hover {
} */

.add-city {
  font-size: 40px;
  color: #000;
  font-weight: 900;
  line-height: 40px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.li-addrestile {
  font-weight: 400px;
  line-height: 1.1;
  font-size: larger;
}
.ltext {
  font-size: 20px;
  line-height: 25px;
  color: #000;
  font-weight: 300;
}
.contactimg {
  /* background-image: url("/public//Images/contactUS1.png"); */
  height: 90vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center;
  transform: scaleX(-1);
  opacity: 0.7;
}

@media only screen and (max-width: 425px) {
  .registerbtn {
    width: 45%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .conatctheadingat {
    font-size: 60px;
    margin-top: 24%;
  }
  .rows {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .address1 {
    grid-template-columns: 1fr;
  }

  .conatctheadingat {
    font-size: 60px;
    margin-top: 24%;
  }

  .contactimg {
    height: 60vh;
  }

  .add-landmark {
    margin-top: 0%;
  }
}

@media only screen and (max-width: 400px) {
  .columnb textarea {
    height: 250px;
    width: 130%; /* Increase height further for smaller mobile devices */
  }
}

@media only screen and (max-width: 530px) {
  .form-groupcaptcha {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .form-groupcaptcha {
    margin-left: 0px;
  }
}

.columnb input[type="text"],
textarea {
  border: none;
  border-bottom: 3px solid orange;
  box-sizing: border-box;
  height: 80px;
  margin-top: 5px;
  outline: none;
  padding: 10px;
  width: 100%;
}

.input::placeholder {
  color: black;
}

.headparadiv {
  color: #fff;
  background-image: linear-gradient(239deg, #007bbd, #003f61);
  opacity: 0.9;
  border-radius: 4px;
  box-shadow: 0 25px 100px 0 rgba(0, 0, 0, 0.15);
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: start;
  align-items: center;

  /* width: auto !important; */
  padding: 2rem;
}

@media only screen and (max-width: 950px) {
  .container-yq {
    grid-template-columns: 1fr;
  }
}

.formaind {
  /* display: flex;
  flex-wrap: wrap; */
  width: 100%;
}
/* .headparadiv, .conparadiv{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  
} */

@media only screen and (max-width: 620px) {
  .headparadiv {
    width: 96vw;
    display: flex;
    flex-wrap: wrap;
  }

  .conparadiv {
    width: 96vw;
    display: flex;
    flex-wrap: wrap;
  }
}
