.BusinessInte-container {
  background-color: purple;
  font-size: 32px;
  width: 100%;
  height: 220px;
  padding-left: 32px;
  padding-top: 100px;
  color: white;
  font-family: 'Oswald', sans-serif;
}

.BusinessInte-heading {
  padding-top: 20px;
  padding-left: 30px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.BusinessInte-description {
  padding-left: 30px;
  font-size: 16px;
  color: #000000;
}

.BusinessInte-main-description {}

.BusinessInte {
  padding-top: 30px;
  color: rgb(0, 0, 0);
  padding-left: 30%;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
  font-family: tungstenw05-medium, Oswald, sans-serif;

}

.business-point {
  background-image: url('https://www.cio.com/wp-content/uploads/2023/05/bi-business-intelligence-ts-100646689-orig-8.jpg?quality=50&strip=all&w=1024');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 2px solid #080808;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(5, 5, 5, 0.2);
  text-align: left;
  max-width: 500px;
  margin-top: 30px;
  margin-left: 10px;
  flex: 1;
  transition: transform 0.3s;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.business-features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 30px;

}

.feature-item {
  margin-top: 30px;
  background-image: url('https://april9.com.au/assets/blog/top-5-automated-testing-tools/cover.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-basis: calc(25% - 20px);
  margin-right: 20px;
  padding: 10px;
  border: 2px solid #080808;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(5, 5, 5, 0.2);
  flex: 1;
  transition: transform 0.3s;

}

.feature-item2 {
  margin-top: 30px;
  background-image: url('https://miro.medium.com/v2/resize:fit:744/1*xNP4u4R1X4CJ4B8J1HH_hA@2x.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-basis: calc(25% - 20px);
  margin-right: 20px;
  padding: 10px;
  border: 2px solid #080808;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(5, 5, 5, 0.2);
  flex: 1;
  transition: transform 0.3s;

}

.feature-item3 {
  margin-top: 30px;
  background-image: url('https://www.iedautomationexpo.in/assets/img/slider/slide2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-basis: calc(25% - 20px);
  margin-right: 20px;
  padding: 10px;
  border: 2px solid #080808;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(5, 5, 5, 0.2);
  flex: 1;
  transition: transform 0.3s;

}

.feature-item1 {

  margin-top: 30px;
  flex-basis: calc(25% - 20px);
  width: auto;
  height: 205px;
  margin-right: 20px;
  padding: 10px;
  border: 2px solid #080808;
  background-image: url('https://sloanreview.mit.edu/wp-content/uploads/2018/10/MAG-Ransbotham-Ratings-Recommendations-1200X627-1200x627.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px;
  display: block;
  box-shadow: 0px 0px 10px rgba(5, 5, 5, 0.2);
  flex: 1;
  transition: transform 0.3s;
}

.feature-item:hover {
  transform: scale(1.05);
}

.feature-item2:hover {
  transform: scale(1.05);
}

.feature-item3:hover {
  transform: scale(1.05);
}

.feature-item1:hover {
  transform: scale(1.05);
}

.business-point:hover {
  transform: scale(1.05);
}

.para-point {
  color: white;
}

.AI {
  font-weight: bold;
  padding-left: 30px;
  padding-top: 30px;
  font-size: 22px;
  padding-bottom: 20px;
}

.two-column-lists {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.two-column-lists li {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  box-sizing: border-box;
}

.h5-headings {

  font-weight: bold;
  padding-left: 20px;
  color: black;
  font-size: 18px;
  margin: 0;
}

.parag {
  padding-top: 10px;
  padding-left: 20px;
  font-size: 16px;
  margin: 0;
}



.BI {
  padding-top: 30px;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin-right: 20px;
  font-family: tungstenw05-medium, Oswald, sans-serif;
}


.expertise {
  font-size: 18px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  border-radius: 20px;
  height: auto;
  padding-top: 10px;
  margin-top: 8px;
  margin-bottom: 10px;
  background-image: url("https://t4.ftcdn.net/jpg/02/33/60/53/360_F_233605359_NlDQuxd4GlouZAUQRUd2YWI5zhC2Lt5Z.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

}


.expertise ul {
  list-style-type: none;
  padding: 0;
}

.expertise li {

  margin-bottom: 20px;
}

.expertise h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.pi {
  padding-left: 20px;
}

@media (max-width: 768px) {

  .business-features {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }


  .feature-item,
  .feature-item1,
  .feature-item2,
  .feature-item3 {
    text-align: left;
    padding: 10px;
  }

  .BusinessInte {
    font-size: 24px;
    text-align: left;
  }

  .business-point {
    max-width: 100%;

  }
}