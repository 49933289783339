p{
    color: #000000;
}
.column {
    display: inline-block;
    width: 50%; 
    vertical-align: top;
    box-sizing: border-box;
    padding: 10px; 
  }
  
 


