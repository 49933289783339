.accordion {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 10px;
  border: none;
}

.accordion-item {
  width: 100%;
}

.accordion-title {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-family: system-ui;
  font-weight: 300;
  font-size: 22px;
  cursor: pointer;
  color: black;
  transition: background-color 0.3s ease;
}

.accordion-title:hover {
  background-color: #d8d7d5;
  color: black;
}

.accordion-title.active {
  background-color: #d8d7d5;
  color: rgb(28, 22, 22);
}

.accordion-content {
  width: 100%;
  padding: 10px;
  height: 100px;
  padding-left: 45px;
  font-family: system-ui;
  font-size: 18px;
  display: none;
  overflow: hidden;
  color: rgb(28, 22, 22);
  font-weight: 300;
  background-color: #d8d7d5;
  transition: max-height 0.3s ease;
}

.accordion-content.active {
  display: block;
  max-height: 100%;
}

.accordion-toggle {
  float: left;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

@media only screen and (max-width: 700px) {
  .accordion-title {
    height: 70px;
    font-size: 20px;
    font-weight: 400;
    font-size: 16px;
  }

  .accordion-content {
    height: 100%;
  }
}